input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.part_table_image_small_preview {
    width: 190px;
}

.table-actions-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /*padding-right: 25px;*/
    margin: 0 auto;
    gap: 6px;
}

.table-actions-container .singl-action {
    padding: 10px 3px;
}

.small-band-part .btn-action-count {
    margin: 0 !important;
}

.part-table-info-container .settings-container {
    border: 1px solid #f0f0f3;
    width: 405px;
    left: unset;
    top: unset;
    right: 0;
}

.part-table-info-container .settings-container .setting-item {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.part-info-textarea {
    width: 100%;
    height: 195px;
    border: 1px solid #f0f0f3;
    border-radius: 5px;
    padding: 9px;
}

.part_table_image_small_preview {
    width: 100px;
    max-height: 100px;
}

.tabCount.disabled .btn-action-count {
    display: none;
}


.main-parts-table td {
    border-bottom: 1px solid #f0f0f3;
    box-sizing: content-box;
}

.main-parts-table th {
    border-bottom: 1px solid #f0f0f3;
    box-sizing: content-box;
}

.main-parts-table thead th {
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: nowrap;
    text-align: center;
    font-size: 13px;
}

.main-parts-table input,
.main-parts-table input.input-val,
.main-parts-table td {
    font-size: 13px !important;
}

.main-parts-table .input-val {
    padding: 5px;
}

.part_name_table_text_container .part-name-input {
    width: 195px;
    max-width: 100%;
}

.y-asics,
.x-asics {
    display: inline-block;
}

.hint-messages {
    position: absolute;
    /*z-index: 2;*/
    left: 2px;
    top: 0px;

}

.y-asics .hint-messages {
    left: 8px;
}

.hint-messages span {
    font-size: 12px;
    color: #4262ff;
    cursor: pointer;
    padding: 3px;
    font-weight: 500;
}

.x-asics input {
    padding: 10.9px;
    text-align: center;
    width: 61px;
    margin-right: 5px;
}

.y-asics input {
    padding: 10.9px;
    text-align: center;
    width: 61px;
    margin: 0 5px;
}

.z-asics input {
    padding: 10.9px;
    text-align: center;
    width: 45px;
    margin-left: 5px;
}

.part_name_table_text_container {
    width: 155px;
    max-width: 155px;
}

.part_light_mode_bands_container {
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #CDCCD7;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0 0px 0px 0px;
    height: 41px;
    max-width: 286px;
    position: relative;
    top: 5px;
    opacity: 1;
    /*border-right: 0px;*/
    overflow: hidden;
}

.part_light_mode_bands_container.part_light_mode_edge_edit_container {
    background: #8979791a;
    margin: 5px 0 0 0;
    border-color: #fea500;
}

.part_light_mode_bands_container.part_light_mode_edge_edit_container path {
    fill: #fea500;
}

.part_light_mode_bands_container .light_mode_input_container:nth-child(5) input {
    border-right: 1px solid #CDCCD7;;
    border-radius: 0px 5px 5px 0px;
}

.hint-band-name {
    position: absolute;
    top: 0;
    font-size: 12px;
    color: #4262ff;
    cursor: pointer;
    padding: 3px;
    font-weight: 500;
}

.light_mode_input_container span,
.light_mode_input_container input {
    width: 35px;
    outline: none;
    border: none;
    border-right: 1px solid #CDCCD7;
    height: 100%;
    text-align: center;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.light_mode_input_container {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

.light_mode_input_container:last-child input {
    border: none;
}

.light_mode_input_container svg {
    margin-left: 5px;
}

.light_all_band_mode input {
    width: 231px;
}

.st0 {
    fill: red;
    stroke: #000000;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.st1 {
    fill: none;
    stroke: #000000;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.st2 {
    fill: none;
    stroke: #000000;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}

.part_band_setting_main_container {
    position: absolute;
    left: 100%;
    opacity: 1;
}

.cutting > div {
    position: relative;
}

.setting.material-settings.band-setting-container {
    margin-left: 24.3px;
    text-align: left;
    margin-top: 0;

}

.setting.material-settings.band-setting-container .settings-container {
    background-color: rgb(243, 244, 248);
    border: none;
    margin-top: -27px;
}

.active_part_band_setting_icon svg path {
    fill: tomato;
}

.active_part_band_setting_icon_pre_joint_style svg path {
    border: 1px solid tomato;
    border-radius: 5px;
    opacity: 1;
}

.part_band_edge_edit_by_side,
.part_band_setting_with_pre_joint_symbol {
    font-weight: bold;
    opacity: 1;
    margin-left: 5px;
    color: rgb(66, 98, 255);
}

.part_band_edge_edit_by_side {
    color: #209e98;
}

.part-glass-setting-container {
    margin: 0 10px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;

}

.part-table-info-container.setting .settings-container {
    background-color: #f0f0f3
}

.small-part-table-format {
    padding: 0 !important;
}

.small-part-table-format td {
    padding: 0 !important;
}

.small-part-table-format .z-asics {
    margin-bottom: 9px;
}

.part-glass-setting-operations-markers-container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
}

.part-glass-setting-operations-marker {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 10px auto;
}

.part-glass-setting-operations-marker.shlifovka {
    background-color: green;
}

.part-glass-setting-operations-marker.polirovka {
    background-color: red;
}

.part-glass-setting-operations-marker.facet {
    background-color: blue;
}


.hint-part {
    display: none;
    position: absolute;
    background: white;
    border: 2px solid #c3c2d0;
    box-shadow: 0px 0px 15px #c3c2d0;
    width: max-content;
    text-align: left;
    /*padding: 10px;*/
    max-width: 300px;
    border-radius: 4px;
    padding: 15px 10px 15px 25px;
}

.hint-part li {
    font-size: 14px;
    color: #605d81;
    list-style: circle;
    margin-bottom: 6px;
}

.hint-part__count .plus:hover .hint-part,
.hint-part.show {
    display: block;
}

.main-parts-table .small-band-part td {
    position: relative;
    padding: 0 5px;
    text-align: center;
    box-sizing: border-box;
}

.small-band-part:not(.small-part-table-format) .hint-messages.hint-count {
    margin-top: -40px !important;
    margin-left: auto;
    text-align: right;
}

.small-band-part .z-asics {
    margin: 0 !important;
}

.small-band-part .singl-action {
    padding: 0 5px;
}

.small-band-part:not(.small-part-table-format) .part_light_mode_bands_container {
    height: 30px;
}

.small-band-part:not(.small-part-table-format) .input-val {
    padding: 5px !important;
    font-size: 14px;
}

.small-band-part:not(.small-part-table-format) .part_light_mode_bands_container_icon svg,
.small-band-part:not(.small-part-table-format) .light_mode_input_container svg {
    width: 14px;
}

.small-band-part:not(.small-part-table-format) .singl-action svg {
    width: 20px;
}

th.sub-header.sub-header-band .material-section-table-image-container {
    width: 35px;
    height: 35px;
    min-width: 35px;
    overflow: hidden;
    flex: 0 0 35px;
}

th.sub-header.sub-header-band .material-section-table-image-container img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

th.sub-header.sub-header-band {
    padding: 5px 0px;
}

.small-band-part .hint-band-name {
    background: black;
    font-weight: 400;
    top: -17px;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
    padding: 0px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.small-band-part.small-part-table-format .hint-messages.hint-count {
    margin-top: 0px !important;
}

.events-turns-operation.loaded {
    pointer-events: none;
    opacity: 0.8;
}

label.form-input-checkbox-label.texture-bars .form-input-checkbox:checked + span:after,
label.form-input-checkbox-label.texture-bars:before {
    content: none;
}

label.form-input-checkbox-label.texture-bars .form-input-checkbox:checked + span:before {
    content: '';
    position: absolute;
    left: calc(50% - 3px);
    top: -15px;
    width: 6px;
    height: 20px;
    transform: rotate(
            45deg
    );
    right: 0;
    text-align: center;
    border-color: #777;
    border-style: solid;
    border-top: none;
    border-left: none;
    border-width: 3px;
}

label.form-input-checkbox-label.texture-bars span {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -10px;
    cursor: pointer;
}

label.form-input-checkbox-label.texture-bars span .line {
    display: block;
    width: 26px;
    height: 3px;
    background: #77777773;

    margin: 2px 0px;
}


@media (max-width: 1024px) {
    .table-actions-container {
        width: max-content;
        /*padding-right: 25px;*/
        /*max-width: 100%;*/
    }

    .part-table-info-container .settings-container {
        width: 100%;
        box-shadow: 0 0 0 transparent;
    }

    .part_name_table_text_container {
        min-width: 120px;
    }

    .part_name_table_text_container input {
        min-width: 100%;
        padding: 10.9px 5px;

    }

    .part_name_table_text_container span.part_name_table_text {
        display: inline-block;
        min-width: 60px;
        max-width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

@media (max-width: 768px) {
    .main-parts-table .small-band-part td {
        /*padding: 0px 10px 5px 0px;*/
    }
}

.part-tab-action-wrap {
    position: relative;
    padding-right: 60px;
    column-gap: 8px;
    row-gap: 12px;
}

.part-tab-action-wrap .singl-action svg {
    width: 20px;
}

.part-tab-action-wrap .part-actions-menu-wrap .singl-action svg {
    width: revert;
}

.part-tab-action-wrap .singl-action {
    padding: 0;
}

.edgeType_band {
    font-size: 8px;
    position: absolute;
    right: 0px;
    top: 0px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 3px;
    background: black;
    color: white;
}

.table-fixed {
    table-layout: fixed;
}


.tabId {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
}

.tabPicture {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
}

.tabNames {
    width: 155px;
    min-width: 155px;
    max-width: 155px;
    padding: 0px 5px;
}

.tabMaterialColumn {
    width: 170px;
    min-width: 170px;
    max-width: 170px;
}

.tabSize {
    width: 220px;
    min-width: 220px;
    max-width: 220px;
}

.tabCount {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
}

.tabTexture {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
}

.tabBand,
.tabBandColumn {
    width: 235px;
    min-width: 235px;
    max-width: 235px;

}

td.tabBandColumn {
    text-align: left !important;
}

.tabAction {
    max-width: 180px;
    width: 180px;
}

.tabAction .material-settings {
    margin: 0 !important;
}


.table-main-detail {
    width: max-content;
    margin: 0 auto;
    box-shadow: 0 0 5px #f0f0f3;
    border: 1px solid #f0f0f3;
    padding: 0 0;
}

.table-main-detail table {
    width: 100%;
}

.btn-action-count {
    display: flex;
    align-items: center;
    margin-top: 15px;
    position: relative;
    padding: 5px;
    justify-content: center;
    color: white;
    width: 100%;
}

span.relative.btn-action-count .hint-messages {
    position: relative;
}

span.relative.btn-action-count .hint-part {
    top: 10px;
}

span.relative.btn-action-count .hint-messages > span {
    display: block;
    text-align: center;
}

.table-main-detail tr:last-child table tr td {
    border-bottom: 0px;
}

span.action_add {
    font-size: 15px;
    font-weight: bolder;
}

.save-part-name {
    background-color: rgb(66, 98, 255);
    color: white;
    font-size: 12px;
    padding: 6px 10px;
    display: block;
    width: max-content;
    max-width: 100%;
    margin: 10px auto;
    text-transform: uppercase;
    border-radius: 4px;
}

.includePartCombine {
    pointer-events: none;
    opacity: 0.5;
}

span.btn-template-paz {
    display: flex;
    width: 30px;
    cursor: pointer;
    align-items: center;
    box-sizing: content-box;
    padding-right: 4px !important;
}

.turn-action span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    box-sizing: content-box;
    padding-right: 0px !important;
}

.template-paz-detail {
    display: grid;
    grid-template-columns: 54px 54px 54px 54px;
    border: 1px solid #33d6b2;
    width: max-content;
    margin: 0 auto;
    /*margin: 5px -10px 0px auto;*/
    border-radius: 5px;
    align-items: center;
    overflow: hidden;
}

.template-paz-detail path {
    fill: #33d6b2;
}


.template-paz-detail .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #CDCCD7;
    padding-left: 3px;
    flex: 0 0 54px;
    width: 54px;
}


.template-paz-detail .item svg {
    width: 16px;
}

.template-paz-detail .item span.id {
    flex: 1 1 35px;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
}

.action-change-operation-cutto .action {
    display: block;
    width: 35px;
    height: 35px;
    margin: 3px auto;
    cursor: pointer;
    box-sizing: content-box;
}

.action-change-operation-cutto + .events-turns-operation {
    margin: 0;
}

.action-change-operation-cutto {
    position: relative;
    z-index: 8;
}

.action-change-operation-cutto .tooltip {
    position: absolute;
    top: -100%;
    left: 0;
    background: white;
    padding: 8px;
    box-shadow: 0 0 15px #c3c2d0;
    display: none;
    border-radius: 3px;
    width: max-content;
}

.action-change-operation-cutto:hover .tooltip {

    display: block;
}

.pagination-container {
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 5px 10px;
}

.pagination-part {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 5px 0;
    justify-content: center;
    flex-wrap: wrap;
    font-weight: 500;
    font-style: italic;
}

.pagination-show-entries {
    margin: 0 5px;
    font-size: 14px;
}

.pagination-show-entries .select-per-page {
    margin: 0 6px;
}

.pagination-show-entries span {
    font-weight: 500;
    /*font-style: italic;*/
    font-size: 13px;

}

.pagination-part .page-item__arrow,
.pagination-part a {
    width: 30px;
    height: 30px;
    display: inline-flex;
    border: 1px solid #ddd;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    cursor: pointer;
}

.pagination-part a.active {
    background: rgb(66, 98, 255);
    color: white;
}

.count-asics {
    display: block;
    width: max-content;
    margin: 0 auto;
}

/*OPERATIONS */
.events-turns-operation,
.events-history-operation {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    /*justify-content: space-between;*/
}

.events-turns-operation li,
.events-history-operation .btn {
    background: white;
    border-radius: 4px;
    padding: 5px;
}

.events-history-operation .btn {
    width: max-content;
}

.events-turns-operation li,
.events-history-operation li {
    margin: 0;
    /*margin: 5px 3px 5px 0;*/
    cursor: pointer;
    padding: 4px;
}

.events-history-operation svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.events-history-operation .inversions.mirror svg path {
    stroke: white !important;
}

.events-history-operation .inversions.mirror {
    border: 1px solid black;
    border-radius: 4px;
    background: black;
}

.events-history-operation .inversions svg {
    transform: rotate(181deg);
}

.events-turns-operation .active {
    background: black;
    color: white;
}

.main-parts-table tr,
.main-parts-table td {
    padding: 5px 0;
}

.main-parts-table .material-select {
    max-width: 150px;
    width: 150px;
    padding: 4px 3px;
}

.part .form-input-checkbox-label.part_id {
    margin-top: 8px;
    justify-content: center;
}

.part .form-field .form-input-checkbox:checked + span:after {
    left: calc(50% - 5px);
    transform: translateX(-50%);
    top: 0;

}

.part .form-input-checkbox-label.part_id span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.part .form-input-checkbox-label.part_id:before {
    border-color: #cdccd7;
    margin: 0px;
    cursor: pointer;
}

.part-postformin-img {
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 30px;
    border: 1px solid #CDCCD7;
    border-radius: 3px;
    z-index: 2;
    background-color: white;
}

.part-postformin-img img {
    position: absolute;
    top: 50%;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translateY(-50%);
}

.part-postformin-img:hover {
    transform: scale(5);
}


.icon-helmet.includes_goods path {
    fill: #4262ff91;
}

.processing-edge-edit-wrap.settings-container {
    border: 2px solid #c3c2d0;
    box-shadow: 0px 0px 15px #c3c2d0;
    z-index: 9999;
}

.processing-edge-edit-side-item {
    position: relative;
    margin: 4px 0;
}

.processing-edge-edit-side-item:not(:last-child) {
    border-bottom: 1px solid #cdccd7;
}

.processing-edge-edit-side-item .processing-edge-edit__header .icon-action {
    position: absolute;
    right: 0;
    top: 8px;
    /*transform: translateX(-50%);*/
}

.processing-edge-edit-side-item .processing-edge-edit__header {
    gap: 8px;
    padding: 8px 20px 8px 0;
}

.processing-edge-edit-side-item .processing-edge-edit__header .title {
    font-size: 15px;
    text-align: left;
}

.processing-edge-edit-side-item .processing-edge-edit__content {
    width: 100%;
    display: grid;
    gap: 10px;
    padding-top: 15px;
    padding-bottom: 25px;
    border-top: 1px solid #cdccd7;
    margin-top: 10px;
    text-align: left;
}

.processing-edge-edit-side-item .processing-edge-edit__content input {
    padding: 11px;
    font-size: 15px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #cdccd7;
}

.processing-edge-edit-side-item .processing-edge-edit__content select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #cdccd7;
    outline: none;
    background-color: #fff;
    min-width: 220px;
    width: 100%;
}

.processing-edge-edit-side-item .label {
    padding-top: 0;
}


.icon-edit-edge {
    width: 24px;
    height: 24px;
    background-size: 100%;
    background: url('data:image/svg+xml,<svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 1080" style="-background:new 0 0 1920 1080;" xml:space="preserve"><style type="text/css">.st0{fill:%23FFFFFF;stroke:%23000000;stroke-miterlimit:10;}.st1{stroke:%23FFFFFF;stroke-miterlimit:10;}</style><polygon class="st0" points="455.5,504.5 658.5,390.5 861.98,390.5 658.74,504.5 611.36,504.5 "/><rect x="450.5" y="511.5" class="st1" width="203.5" height="45"/><polyline class="st1" points="661,556.5 863.98,438.73 863.98,397.5 661,511.5 "/></svg>');
}

.edit-edge_preview {
    width: 35px;
    position: absolute;
    right: 0;
    cursor: pointer;
    transition: all 0.9ms;
}

.modal-img-edge_preview {
    display: block;
    max-width: 100%;
    margin: 20px auto;
}

.edit-edge_preview:hover {
    transform: scale(3);
}

.edit-edge-preview-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 40px;
    position: relative;
    padding-right: 40px;
}

td.part-td-option.tabSize.small-part-size {
    /*width: 200px;*/
    /*min-width: 200px;*/
}

td.part-td-option.tabSize.small-part-size > div {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 10px;
}

td.part-td-option.tabSize.small-part-size div .input-val {
    margin: 0;
}

.main-parts-table .input-val {
    /*font-size: 12px;*/
}

.main-parts-table .full-band-part td {
    padding: 5px 5px;
    /*box-sizing: content-box;*/
    box-sizing: border-box;
}

.material-settings.material-column select {
    min-width: 100%;
    width: 100%;
}


.part-action-menu .settings-title {
    /*margin-bottom: 25px;*/
}

.main-parts-table .cutting select.cutting-type {
    padding: 5px 2px;
    border-radius: 4px;
    width: 120px;
    margin-left: 5px;
}


.settings-container.part-action-menu .settings-content {
    gap: 15px;
}

.part-tab-action-wrap .part-actions-menu-wrap {
    position: absolute;
    right: 30px;
    top: 0;
}

.part-actions-menu-wrap .part-actions-menu-btn {
    cursor: pointer;
    border: 1px solid var(--border);
    border-radius: 2px;
    padding: 2px;
}

label.form-input-checkbox-label.part-texture span.form-input-checkbox-checked {
    display: block;
    width: 19px;
    height: 19px;
    margin-left: -25px;
    margin-right: 10px;
    line-height: 18px;
    text-align: center;
    opacity: 0;
}

label.form-input-checkbox-label .form-input-checkbox:checked + span.form-input-checkbox-checked {
    opacity: 1;
}

label.form-input-checkbox-label.texture-bars span.form-input-checkbox-checked {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    font-size: 0;
}

label.form-input-checkbox-label.part-texture .form-input-checkbox:checked + span:after {
    content: none;
}