.home {
    display: flex;
    height: 100vh;
    max-height: -webkit-fill-available;
    -webkit-bleedingedgethingy: 1;
    bleedingedgethingy: 1;
    /*height: calc(100vh - calc(100vh - 100%));*/
    /*min-height: 100vh;*/
    flex-direction: column;
}
.home.home-box {
    height: calc(100vh - 24px);
}


.home .main {
    flex: 1;
    overflow-y: auto;
}
.modal-page {
    position: fixed;
    top: 0;
    left: 0;
    /*height: 100vh;*/
    width: 100%;
    z-index: 100;
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - calc(100vh - 100%));
}
.nightstands-wrapper.modal-page {
    z-index: 48;
}
.material-settings {
    position: relative;
    margin-left: 15px;
}
.material-settings .settings-container {
    right: 0;
    left: unset;
    top: unset;
    /*border: 1px solid #f0f0f3;*/
    border: 2px solid #c3c2d0;
    box-shadow: 0px 0px 15px #c3c2d0;
}
.table-part-material-section-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;

}
.maerials_project_client_material_icon {
    position: absolute;
    top: -13px;
    left: -13px;
    width: 30px;
    background-color: white;
}
.project_materials_item_container {
    position: relative;
}
.setting-item-button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}
.setting-item-button-container {
    margin-top: 19px;
}
.setting-item-button svg {
    margin-right: 5px;
}
.main-content-separator {
    width: 100%;
    height: 1px;
    margin: 15px 0;
    background-color: #f0f0f3;
}
.settings-container .main-content-separator {
    margin-top: 31px;
}

@media (max-width: 1024px) {
    .material-settings .settings-container{
        box-shadow: 0 0 0 transparent;
        border: none;
    }
    .home.home-box {
        height: calc(100vh - 24px - calc(100vh - 100%));
    }
}

.footer-center-information-container{
    flex: 1 1 auto;
    text-align: center;
}
@media (max-width: 576px){
    .footer-center-information-container{
        order: 3;
        flex: 0 0 100%;
        text-align: center;
    }
    .footer-container{
        flex-wrap: wrap;
    }
}