.actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  /*height: 56px;*/
  height: 50px;
  border-radius: 4px;
}
.singl-action {
  cursor: pointer;
  position: relative;
  padding: 10px;
}
.cnv svg{
  width: 20px;
}
.cnv.inversions {
  border: 1px solid black;
  border-radius: 4px;
  background: black;
}
.cnv.inversions svg path ,
.cnv.inversions svg line {
  stroke: white !important;
}
.add {
  position: absolute;
  top: 54px;
  left: 0;
  width: 320px;
  /*height: 269px;*/
  height: auto;
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  padding: 24px;
  background-color: #fff;
  z-index: 20;
  border: 1px solid #f0f0f3;
}
.add .wrapper-add__list{
  /*margin-top: 20px;*/
}
.wrapper-add__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
  gap: 5px;
}

.add-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 0 0 15px 0;
  border-bottom: 1px solid #f0f0f3;
  padding-bottom: 15px;
}

.add-search {
  padding: 8px;
  border-radius: 4px;
  border-color: #4262ff;
  outline: none;
}
.prints-singles-actions-container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 8px;
}
.prints-singles-actions-container .singl-action-item {
  /*margin-bottom: 19px;*/
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.prints-singles-actions-container .singl-action-item svg {
  margin-right: 5px;
}
.prints-singles-actions-container .singl-action-item svg.icon-arrow{
  width: 8px;
}
.prints-singles-actions-container .singl-action-item:first-child {
  /*margin-top: 19px*/
}
.add.prints-actions-container {
  height: auto;
  width: 359px;
  position: absolute;
  top: 41px;
  left: 31px;
}
.add.prints-actions-container.operations-print-actions-container{
  left: -345px;
}
.add.prints-actions-container.prints-actions-container-calculate {
  top: unset;
  bottom: 41px;
  left: 0px;
}
@media (max-width: 1024px) {
  .add.prints-actions-container.prints-actions-container-calculate{
    bottom: 0;
  }
}
.header_top_actions_item_name {
  padding-left: 15px;
}
.header_top_actions_item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.chpu-group svg{
  width: 25px;
  height: auto;
}
.print_cutting_cards__container.print-gl-actions .gib-drop-btn {
  margin: 0;
  width: 100%;
  display: flex;
  min-width: 45px;
}
.gib-drop-btn {
  width: 30px;
  height: 30px;
  border: 1px solid #080d48;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 8px;
  border-radius: 3px;
  font-weight: bold;
}


/*  --------- АДАПТИВ --------- */

@media (max-width: 1024px) {
  .actions-container{
    box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);
    border-radius: 4px;
    position: fixed;
    bottom:95px;
    left: 15px;
    right: 15px;
    z-index: 2;
    width: max-content;
    margin: 0 auto;
    max-width: 90%;
    height: revert !important;
  }
  body.substrate-part-info-container_settings ,
  .substrate-material-settings ,
  body.substrate-settings ,
  body.substrate-add ,
  body.substrate-vertical {
    position: relative;
  }
  body.substrate-part-info-container_settings:before ,
  .substrate-material-settings:before ,
  body.substrate-settings:before,
  body.substrate-add:before,
  body.substrate-vertical:before{
    content:'';
    position:absolute;
    left: 0;
    right:0;
    bottom:0;
    background: rgba(0, 0, 0, 0.3);;
    z-index: 2;
    top: 0;
  }
  .add.prints-actions-container,
  .add{
    position: fixed;
    top:auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.08);
    border-radius: 24px 24px 0px 0px;
    height: auto;
  }
  .add:before{
    content: '';
    display: block;
    width: 35px;
    height: 4px;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #E9E9E9;
    border-radius: 5px;
  }
  .add .add-title{
    border-bottom: none;
  }
.add .wrapper-add__list{
  display: flex;
  align-items: center;
  padding: 20px 0;
  /*justify-content: space-between;*/
  flex-direction: row;
  margin-top: 0;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.add .wrapper-add__list .header_top_actions_item:not(:last-child){
  margin-right: 5px;
}
.add .wrapper-add__list .header_top_actions_item{
  flex-direction: column;
}
  span.header_top_actions_item_name {
    padding: 0px;
    text-align: center;
    margin-top: 4px;
    font-size: 12px;
  }
}
@media (max-width: 992px) {

}@media (max-width: 768px) {

  .wrapper-add__list svg:first-child path:nth-child(3){
    /*display: none;*/
  }

}@media (max-width: 564px) {
  .actions-container .singl-action{
    margin-right: 3px;
    padding: 15px 5px;
  }
}@media (max-width: 425px) {

}@media (max-width: 375px) {

}

.prints-singles-actions-container svg {
  width: 25px;
  height: 25px;
}
.actions-container svg {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
}


.singl-action-item.cnv.inversions {
  color: white;
}
.singl-action-item.helper-article{
  padding-right: 25px !important;
}