
.file-manager-add-file .close-modal path {
    stroke: black !important;
    margin-left: auto;
}

.file-manager-header {
    text-align: right;
}

.file-manager-add-file {
    border-bottom: 0.5px solid #cdccd7 !important;
    padding-bottom: 25px;
    margin-bottom: 25px;
    flex: 0 0 100%;
    width: 100%;
}

button.file-manager-btn__add {
    margin-left: auto;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    padding: 12px 15px;
}

.file-manager__container {
    flex-direction: column;
    justify-content: flex-start;
}

.list-files {
    margin: 20px 0px 0px 0px;
    width: 100%;
}


.list-files > div {
    display: flex;
    align-items: center;
    padding: 5px 10px;
}

.list-files .count,
.list-files a {
    font-size: 20px;
    margin-right: 6px;
}

.list-files .count {
    color: black;

}

.list-files svg path {
    stroke: red !important;
}

.added-files-name p {
    margin-bottom: 4px;
    text-transform: uppercase;
    color: green;
    margin-top: 0px;
}

.added-files-name .wrap span {
    margin-right: 8px;
}

.list-files a {
    color: #050038;
    font-weight: 400;
    text-decoration: none;
    margin-right: auto;
    max-width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-files > div {
    border-bottom: 0.5px solid #cdccd7 !important;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
}

.list-files > div:hover {
    background-color: rgba(66, 98, 255, .1);
}

.list-files .count {
    position: static;
    top: initial;
    left: inherit;
    transform: none;
    background: transparent;
}