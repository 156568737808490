.table-wrapper {
    padding: 24px;
}

table {
    width: 100%;
}

td {
    vertical-align: baseline;
}

td > img {
    vertical-align: top;
}

thead {
    text-align: left;
}

.sub-header {
    font-size: 15px;
    font-family: "Open Sans" sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #605d81;
    padding: 10px 0;
    text-align: start;
}

.color {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: red;
    vertical-align: middle;
    margin-right: 12px;
}

.edit-btn {
    margin-left: 5px;
    cursor: pointer;
}

.material-select {
    outline: none;
    width: 170px;
    padding: 8px 12px;
    border-radius: 4px;
    margin-bottom: 12px;
    background-color: #fff;
    border: 1px solid #cdccd7 !important;
}

.input-val {
    width: 56px;
    padding: 10.9px;
    font-size: 15px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #cdccd7;
    text-align: center;
}

.part-count-empty input {
    border-color: #ff00007d !important;
    box-shadow: 0 0 3px 0px red;
}

.light_mode_input_container input:focus {
    border: 1px solid rgb(255, 63, 83) !important;
}

.cutting select:focus {
    border-color: rgb(255, 63, 83) !important;
    border-width: 1px !important;
}

@keyframes borderColorChange {
    0% {
        border-color: orange;
        box-shadow: 0 0 7px 0px orange;
    }
    100% {
        border-color: orange; /* Change this to your desired color */
        box-shadow: 0 0 0px transparent;
    }
}

.part-td-option input.input-val:focus {
    animation: borderColorChange 950ms infinite;
}

.part-td-option input.input-val {
    border: 1px solid #cdccd7; /* Initial border color */
    transition: border-color 0.3s ease; /* Smoothens the return to the initial state when focus is lost */
}

.hintType {
    position: absolute;
    top: 0px;
    font-size: 13px;
    padding: 7px;
    border-radius: 3px;
    left: 0;
    right: 0;
    color: rgb(66, 98, 255);
    font-weight: 500;
    z-index: 999;
    width: max-content;

    margin-top: -35px;
    opacity: 0;
    pointer-events: none;
}

.input-val:focus + .hintType {
    opacity: 1;
}

.x-asics {
    position: relative;
}

.y-asics {
    position: relative;

}

.z-asics {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
}

.count-asics::after,
.x-asics::after {
    content: "x";
    position: absolute;
    /*top: -7px;*/
    top: 0px;
    right: 10px;
    font-size: 12px;
    color: #4262ff;
}

.count-asics::after {
    content: "Σ";
    top: 3px;
    right: 5px;
}

.y-asics::after {
    content: "y";
    position: absolute;
    /*top: -7px;*/
    top: 0px;
    right: 10px;
    font-size: 12px;
    color: #4262ff;
}

.z-asics::after {
    content: "z";
    position: absolute;
    top: 2px;
    right: 5px;
    font-size: 12px;
    color: #4262ff;
}

.test {
    margin-top: 27px;
}

.cutting {
    max-width: 300px;
}

.cutting-left,
.cutting-right,
.cutting-top,
.cutting-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px;
}

.cutting-sides {
    margin-top: 15px;
}

.cutting-value {
    padding: 10.9px;
    border-radius: 4px;
    border: 1px solid #cdccd7;
    outline: none;
    max-width: 50px;
    margin-left: 5px;
}

.cutting-type {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #cdccd7;
    outline: none;
    background-color: #fff;
    width: 150px;
    margin-left: 5px;
}

.material-section-table-image-container {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid #f0f0f3;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    overflow: hidden;
}

.material-section-table-image-container img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.table-part-material-section-container > div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.products_material_section_add_part_icon {
    /*margin-left: 15px;*/
    margin: 0px 8px;
}

.project_materials_item_info_icon {
    width: 38px;
    height: 38px;
    margin-top: 9px;
}

.project_materials_item_delete {
    cursor: pointer;
}

.main-parts-table-button-top-scroll {
    position: fixed;
    right: 45px;
    bottom: 90px;
}

.main-parts-table {
    position: relative;
    scroll-behavior: smooth;
}

.selectedPart {
    background-color: rgba(66, 98, 255, 0.1) !important;
}

.table-wrapper:not(.isFocus) tr:hover {
    background-color: rgba(66, 98, 255, 0.1);
    transition-delay: 1ms;
    transition-duration: 300ms;
    transition-property: all;
    transition-timing-function: linear;
}

.table-wrapper .no-hover_tr tr:not(.selectedPart):hover {
    background-color: transparent;
    transition: none;
}

.table-wrapper.isFocus .focused {
    background-color: rgba(66, 98, 255, 0.1);
}

.table-wrapper tr {
    transition-delay: 1ms;
    transition-duration: 300ms;
    transition-property: all;
    transition-timing-function: linear;
    background-color: white;
}


.part-td-option {
    min-width: 220px;
}

/*  --------- АДАПТИВ --------- */

@media (max-width: 768px) {
    body * {
        font-size: 15px;
    }

    .field .settings-section .field-title,
    .settings-section .field-title,
    .label,
    select, input, .input-val {
        font-size: 13px;
    }

    .sub-header {
        font-size: 13px;
        padding: 5px 0px;
    }

    .material-section-table-image-container {
        width: 25px;
        height: 25px;
        margin-right: 10px;
        max-width: 25px;
    }

    .table-wrapper {
        padding: 0px 15px;
    }

    .main-parts-table thead th {
        /*padding-bottom: 5px;*/
        padding: 10px 10px 10px 0px !important;
        font-size: 12px;
    }

    .main-parts-table td {
        padding: 5px 10px 5px 0px;
    }

    .main-parts-table-button-top-scroll {
        display: none;
    }
}

.table-wrapper.main-parts-table .material-select {
    max-width: 170px;
}

.opening-details-part {
    animation-name: openingDetailsPart;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;

}

@keyframes openingDetailsPart {
    from {
        background-color: rgba(66, 98, 255, 0.1);
    }
    to {
        background-color: white;

    }
}


.filter-part-container .filter-icon {
    max-width: 20px;
    margin-right: 5px;
}

.filter-part-container .filed-group {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
}

.filter-part-container .filed-group .filed-group-range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex: 1 1 100%;
    font-size: 14px;
    color: #222;
    margin-bottom: 8px;
}


.filter-part-container .filed-group label {
    font-size: 14px;
    color: black;
    margin-bottom: 6px;
    font-weight: 500;
}

.filter-part-container .filed-group.filed-group-range label {
    flex-direction: row;
    margin: 0px 6px 0px 0px;
}


.filter-part-container .filed-group input, .filed-group select {
    width: 100%;
    min-width: 100%;
}

.filter-part-container {
    width: 100%;
    flex: 0 0 100%;
}

.filter-part-container .btn {
    margin-top: 5px;
    outline: none;
    box-shadow: 0 0 0 transparent;
    border: none;
}

.filter-part-container .btn-clear {
    display: flex;
    align-items: center;
    width: 35px;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #4262ff;
    margin-left: auto;
    cursor: pointer;
}

.filter-part-container .range-slider {
    margin-bottom: 15px;
}

.filter-parts-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.filter-parts-btn > * {
    margin: 0px 4px;
}

.filter-parts-btn .btn-clear {
    max-width: 25px;
    max-height: 25px;
    filter: brightness(0) saturate(100%) invert(77%) sepia(84%) saturate(1299%) hue-rotate(350deg) brightness(103%) contrast(96%);
}

.filter-part-container input,
.filter-part-container select {
    font-size: 14px;
}

.filter-part-container ul.radio-list {
    display: flex;
    align-items: center;
    padding: 0;
    flex-wrap: wrap;
}

.filter-part-container ul.radio-list li {
    margin-right: 15px;
}


.filter-part-container ul.radio-list input[type="radio"] {
    margin-right: 5px;
}

.filter-part-container .field-group-radio {
    margin: 15px 0px;
}

.filter-part-container .filter-parts-form {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

.filter-part-container .fixed-btns {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 15px;
}

.filter-part-container .filter-parts-form .scrolled-block {

}

.group-cnc_values {
    padding-left: 25px;
}

/*@keyframes borderColorChange {*/
/*    0% {*/
/*        border-color: orange;*/
/*        box-shadow: 0 0 7px 0px orange;*/
/*    }*/
/*    100% {*/
/*        border-color: orange; !* Change this to your desired color *!*/
/*        box-shadow: 0 0 0px transparent;*/
/*    }*/
/*}*/

.btn-postforming {
    animation: borderColorChange 950ms infinite;
    border: 1px solid orange;
    border-radius: 3px;
    overflow: hidden;
}

.btn-postforming img {
    cursor: pointer;
    max-width: 45px;
}


.start-commands-container .item {
    flex: 0 0 calc(100% - 30px);
    margin: 10px auto;
}

.start-commands-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1100px;
    margin: 0 auto;
    /* background: #f2f2f2; */
}

@media (min-width: 576px) {
    .start-commands-container .item {
        flex: 0 0 calc(50% - 30px);
        margin: 15px 15px;
    }

}

.start-commands-container .item .btn {
    border-color: #3586ff;
    background-color: white;
    color: #3586ff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0px !important;
}

.start-commands-container .item .btn span {
    margin-left: 8px;
}

.start-commands-container .item p {
    font-size: 16px;
    line-height: 23px;
    color: #222;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

/*@keyframes blink {*/
/*    0% { stroke: green; }*/
/*    50% { stroke: rgba(0, 128, 0, 0.5); }*/
/*    100% { stroke: green; }*/
/*}*/


/*.animated-add-part path{*/
/*    stroke-width: 4;*/
/*    animation: blink 1s infinite;*/
/*}*/