.cabinet-section {
    margin: 0 0 120px;
}

.cabinet-user-data{
    display: grid;
    grid-template-columns: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 25px;
    margin: 35px 0;
}
.user-data .user-data-panel{
    display: flex;
    justify-content: flex-end;
}
/*.user-data .field{*/
/*    border: 1px solid #f3f4f8 !important;*/
/*    border-radius: 8px;*/
/*}*/
.cabinet-firms .card_body {
    max-height: 350px;
    overflow-y: auto;
}

.cabinet-firms  .card_body {
    padding: 0;
}
.list-none  li,
.cabinet-client-firms .card .card_body ul li {
    margin: 4px 0px;
    padding: 4px 8px;
    font-size: 13px;
    /*color: var(--black-text);*/
    border-bottom: 1px solid #f3f4f8;
}

.active-firm {
    /*border-left: 6px solid green;*/
}

.switch-firm {
    /*border-left: 6px solid orange;*/
}
.list-none  li:last-child,
.cabinet-client-firms .card .card_body ul li:last-child {
    border-bottom: none;
}

.cabinet-client-firms .card .card_body ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.title {
    /*color: var(--black-text);*/
}

.county {
    color: #999;
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 5px;
}

.cabinet-client-firms {
    margin: 35px 0;
}

.list-none {
    list-style: none;
    margin: 0;
    padding: 0;
}

@media (min-width: 768px ) {
    .cabinet-user-data,
    .cabinet-client-firms {
        grid-template-columns: 48% 48%;
    }
    .filed-checkbox label,
    .list-none  li,
    .cabinet-client-firms .card .card_body ul li {
        font-size: 15px;
    }
}
@media (min-width: 1024px  ) and (max-width: 1200px) {
    .cabinet-user-data,
    .cabinet-client-firms {
        grid-template-columns: 100%;
    }
    .filed-checkbox label,
    .list-none  li,
    .cabinet-client-firms .card .card_body ul li {
        font-size: 15px;
    }
}
@media (min-width: 1600px ) {
    .cabinet-user-data,
    .cabinet-client-firms {
        grid-template-columns: repeat(3, 32%);
    }
}
.exit{
    color: #000;
    font-weight: 600;
}
.cabinet-connected-channel .list-none .checkmark{
    top: 50%;
    transform: translateY(-50%);
}
.cabinet-connected-channel .list-none label{
    margin-bottom: 0;
}

.connected-firm-item {
    display: grid;
    grid-template-columns: 1fr auto;
    /*grid-template-columns: 50% auto 30px;*/
    gap: 15px;
    align-items: flex-start;
}
.connected-firm-submenu {
    background: #f2f2f2;
    margin:-10px 0 10px 0;
    padding: 5px;
    list-style: none;
}
.connected-firm-submenu li .value{
    text-align: right;
}
.connected-firm-submenu li{
    font-size: 14px;
    color: #222;
    display: flex;
    justify-content: space-between;
}

.connected-firm-submenu li:not(:last-child) {
    border-bottom: 1px solid white;
}


@media (max-width: 576px) {
    .cabinet-connected-channel .resend-action,
    .cabinet-connected-channel .toggle .toggle-label{
        text-align: right;
        font-size: 12px;
    }
    .cabinet-connected-channel .toggle{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

