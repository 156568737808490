:root {

}

.btn-custom {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    /*color: ;*/
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-custom-primary {
    background: var(--blue);
    color: white;
    border-radius: 3px;
    border: 1px solid var(--blue);
}

.btn-custom-danger {
    background-color: var(--danger);
    border-color: var(--danger);
    color: white;
}

.btn-custom-success {
    background-color: var(--success);
    border-color: var(--success);
    color: white;
}

.btn-custom-medium {
    font-size: 1rem;
    padding: 8px 16px;
}

.btn-custom-small {
    font-size: 12px;
    padding: .25rem .5rem;
}

.btn-custom-large {
    font-size: 1.25rem;
    padding: .5rem 1rem;
}

