.settings {
  position: relative;
}
.settings-container {
  width: 315px;
  position: absolute;
  top: 34px;
  left: -300px;
  background-color: #fff;
  z-index: 30;
  border: 1px solid #f0f0f3;
}
.settings-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.settings-title {
  margin: 0 0 8px 0;
  font-size: 15px;
  font-weight: 600;
}
.setting-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  margin: 8px 0px;
}

.setting-item p{
  margin: 0px;
  font-size: 13px;
}
.setting-item .label{
  padding: 0;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 13px;
}
.setting-item:last-child {
  margin-bottom: 24px;
}
.setting-item .form-input{
  height: revert;
  padding: 10px 10px;
  font-size: 13px;
}
.settings-content .setting-item-button svg{
  max-width: 18px;
  max-height: 18px;
}
.settings-content .setting-item-button-container{
  margin: 4px 0;
}
@media (max-width: 1024px) {
  .settings-content {
    padding: 24px 15px;
  }
  .settings-content p.setting-info__title{
    margin-top: 15px;
  }
  .settings-content{
    background: #FFFFFF;
    box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.08);
    border-radius: 24px 24px 0px 0px;
    position: relative;
  }
  .settings-container{
    position: fixed;
    top:auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.08);
    border-radius: 24px 24px 0px 0px;
    height: auto;
    z-index: 9999;
  }
  .settings-container:after{
    content: '';
    display: block;
    width: 35px;
    height: 4px;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #E9E9E9;
    border-radius: 5px;
  }
  .shadow{
    display: block;
    background:  rgba(0, 0, 0, 0.3);
    position: fixed;
    z-index: 3;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
  }

}
