.card {
    border: 1px solid #d9d8e1;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
}

.card .card_header {
    border-bottom: 1px solid #d9d8e1;
    color: #605d81;
    font-size: 16px;
    text-align: center;
    padding: 8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card .card_body {
    /*border-top: 1px solid #d9d8e1;*/
    padding: 8px;
}

.card .card-footer {
    border-top: 1px solid #d9d8e1;
    display: flex;
    justify-content: center;
    padding: 8px;
}

@media (min-width: 992px) {
    .card .card_header {
        font-size: 18px;
    }
}

.card_header .icon-arrow {
    transform: rotate(-90deg);
}
.card_header .icon-arrow-open {
    transform: rotate(90deg);
}
.card_header   .circle-arrow{
    margin-left: 15px;
    width: 20px;
}