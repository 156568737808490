
/* The container */
.filed-radio {
    display: flex;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 16px;
    align-items: center;
}

/* Hide the browser's default radio button */
.filed-radio input {
    left: -100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    transform: translateY(-50%);
}

/* On mouse-over, add a grey background color */
.filed-radio:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.filed-radio input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.filed-radio input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.filed-radio .checkmark:after {
    top: 50%;
    left: 50%;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: white;
    transform: translateY(-50%) translateX(-50%);
}


/*Label for toggle switch*/
.toggle {
    cursor: pointer;
    display: inline-block;
}

.toggle-switch {
    display: inline-block;
    background: #ccc;
    border-radius: 16px;
    width: 45px;
    height: 26px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
}
.toggle-switch:before, .toggle-switch:after {
    content: "";
}
.toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.toggle-checkbox:checked + .toggle-switch {
    background: #56c080;
}
.toggle-checkbox:checked + .toggle-switch:before {
    left: 21px;
}

.toggle-checkbox {
    position: absolute;
    visibility: hidden;
}

.toggle-label {
    margin-left: 5px;
    position: relative;
    top: 2px;
    font-size: 13px;
}




.filed-phone-mask {
    display: grid;
    border: 1px solid var(--border);
    grid-template-columns: 120px 1fr;
    overflow: hidden;
    height: 51px;
}

.filed-phone-mask select {
    padding: 0 0 0 0;
    border-radius: 0;
    border: 0;
    height: 100%;
    font-size: 14px;
    text-align: center;
    width: 100%;
    border-right: 1px solid var(--border);
}
.filed-phone-mask input:focus{
    outline: none;
}
.filed-phone-mask input{
    border: none;
    padding-left: 8px;
}