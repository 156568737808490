body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.part_table_row_disabled {
    position: relative;
}

.part_table_row_disabled:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
}

.d-flex {
    display: flex;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-auto {
    flex: 1 0 auto;
}

.flex-row {
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.items-center {
    align-items: center;
}

.items-end {
    align-items: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center !important;
}

.justify-end {
    justify-content: end !important;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.d-none {
    display: none !important;
}
.gap-8 {
    gap: 8px;
}
.gap-12 {
    gap: 12px;
}

.technical-wrap p {
    font-size: 18px;
    font-weight: 500;
}

.technical-wrap ul li {
    font-size: 16px;
    margin-bottom: 8px;
    color: #605d81;

}

.technical-wrap ul li a {
    margin-left: 8px;
}

.technical-modal .modal-wrapper {
    width: 480px;
}

.technical-modal .modal-wrapper .modal-header h3 {
    line-height: 1;
    font-size: 22px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    /*box-shadow: inset 0 0 5px grey;*/
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #007bff;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #007bff;
}

.select-per-page {
    outline: none;
    border: 1px solid #cdccd7 !important;
    padding: 5px;
    border-radius: 5px;
}

.icon-arrow-right {
    transform: rotate(-180deg);
    background-image: url('data:image/svg+xml,<svg class="back-arrow" width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 1L0.999999 8L7 15" stroke="%23050038" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>')
}

.icon-arrow-left {
    background-image: url('data:image/svg+xml,<svg class="back-arrow" width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 1L0.999999 8L7 15" stroke="%23050038" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>')
}

.icon-arrow-left, .icon-arrow-right {
    width: 12px;
    height: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-project-version {
    cursor: pointer;
    margin: 0px 5px 0px 8px;
}

.tooltip-def {
    position: revert;
}

.tooltip-def .tooltiptext {
    display: none;
    position: absolute;
    top: 0;
    background-color: #f2f2f2;
    padding: 5px;
    z-index: 20;
    border: 1px solid #f0f0f3;
    border-radius: 5px;
}

.tooltip-def .icon {
    background-color: #007bff;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.tooltip-def .icon:hover + .tooltiptext {
    display: flex;
}

.packed-content {
    width: 100%;
}

.material-selected-filter-page .close,
.packed-content .packed-head .close {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.stroke-black path,
.packed-content .packed-head .close svg {
    max-width: 45px;
    padding: 0px;
}

.stroke-black path,
    /*.material-selected-filter-page .close-modal path,*/
.packed-content .packed-head .close path {
    stroke: black;
}

.btn-switch-material {
    width: max-content;
    padding: 2px 10px;
    border: 1px solid var(--gray-violet);
    border-radius: 5px;
}

.btn-switch-material-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.btn-switch-material.active {
    background: #007bff;
    border-color: #007bff;
}

.btn-switch-material.active path {
    fill: white;
}

@media (max-width: 1201px) {
    .btn-switch-material.desc {
        display: none;
    }

    .btn-switch-material.mob {
        display: flex;
    }
}

@media (min-width: 1200px) {
    .btn-switch-material.desc {
        display: flex;
    }

    .btn-switch-material.mob {
        display: none;
    }
}

.help-article-modal ul,
.help-article-modal ol,
.help-article-modal ol li,
.help-article-modal ul li {
    list-style: revert;
}


@keyframes blink_path {
    0% {
        stroke: green;
    }
    50% {
        stroke: rgba(0, 128, 0, 0.5);
    }
    100% {
        stroke: green;
    }
}


.overflow-scrolled {
    overflow-x: auto;
}

.overflow-scrolled table {
    width: max-content;
    min-width: 100%;
}

.form-control {
    padding: 11px;
    width: 100%;
    font-size: 15px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #cdccd7;
}

.select-control {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #cdccd7;
    outline: none;
    background-color: #fff;
    min-width: 220px;
    width: 100%;
}


.carousel .carousel-slider {
    max-height: 100%; /* Ограничиваем высоту карусели */
}






