.offline-form {
    margin: 20px 0px;
}

.import-offline-container {
    padding: 20px 25px 40px 25px;
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.massage-success {

    text-align: center;
    font-size: 22px;
}

.import-offline-container .price {
    font-size: 16px;
    font-weight: 600;

}

.import-offline-container .price span {
    font-size: 16px;
    font-weight: 600;
    color: green;
}

.import-offline-container  .info {
    border: 1px solid #d9d8e1;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 25px;
    /*background: rgb(66, 98, 255);*/
    position: relative;
}

.import-offline-container  .info p {
    margin: 0px;
    line-height: 20px;
    color: #605d81;
    /*color: white;*/
}

.import-offline-container  .info p:first-child {
    margin-bottom: 15px;
}

.info p a {
    color: #4262ff;
    font-weight: 500;
}

.import-offline-container  .info:before {
    content: 'i';
    font-style: italic;
    font-size: 22px;
    color: white;
    width: 25px;
    height: 25px;
    background: #4262ff;
    display: block;
    text-align: center;
    line-height: 21px;
    border-radius: 50%;
    position: absolute;
    left: -12.5px;
    top: -12.5px;
}