.modal.fade.show {
    display: block !important;
}

.input__wrapper {
    width: 100%;
    position: relative;
    margin: 15px 0;
    text-align: center;
}

.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.input__file-icon-wrapper {
    height: 60px;
    width: 60px;
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 1px solid #d9d8e1;
}

.input__file-button-text {
    line-height: 1;
    margin-top: 1px;
}

.input__file-button {
    width: 100%;
    max-width: 100%;
    height: 60px;
    background: transparent;
    color: black;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 auto;
    border: 1px solid #d9d8e1;
}

.input__file-icon-wrapper svg {
    width: 45px;
    height: 45px;
}

.upload_file_project_form {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.import_projects_change_data_s_container {
    width: 100%;
}

.import_projects_change_data_item_info {
    width: 78%;
    display: grid;
    gap: 6px;
}

.import_projects_change_data_item_form {
    width: 20%;
    margin-left: auto;
}

.import_projects_change_data_item_form .form-input {
    font-size: 14px;
    padding: 5px;
    height: revert;
}

.import_projects_change_data_item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 0;
    /*margin-bottom: 19px;*/
    border-bottom: 1px solid #f0f0f3;
}

.import_projects_change_data_title h3 {
    margin: 0 0 25px 0;
    font-size: 16px;
}

.import_projects_change_data_item_info_item {
    font-size: 14px;
    display: grid;
    grid-template-columns: 130px 1fr;

}

.import_projects_change_data_item_info_item:last-child {
    font-size: 14px;
}

.import_projects_change_data_item_info_item > b {
    display: inline-block;
    opacity: 1;
}

.import_projects_change_data_items {
    margin-bottom: 10px;
}

.add-material.upload_file_project_form .create-project {
    display: block !important;
}

.import_projects_change_data_item_info_item span {
    text-align: right;
}

.import_projects_change_data_item_form .form-input {
    font-size: 14px;
    padding: 5px;
    height: revert;

}

.import_projects_change_data_item_info_item:nth-child(even) {
    background: #f2f2f2;

}

.import_projects_change_data_item_info_item {
    padding: 2px 5px;
}
.import_projects_change_data_s_container.add-material.forms .form-input{
    padding: 10px 10px;
    height: revert;
}
.import_projects_change_data_s_container.add-material.forms .form-input-checkbox-label {
    font-size: 14px;
}
.import_projects_change_data_s_container.add-material.forms .form-input-checkbox-label:before {
    width: 15px;
    flex: 0 0 15px;
    height: 15px;
    margin: 0;
}