.page-container {
    background: white;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    z-index: 9;
    padding: 35px 15px;
}

.packaging-printing-page strong {
    color: #605d81;
}

.packaging-printing-page .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    margin-bottom: 25px;
}

.packaging-printing-page .header .close .close-modal {
    padding: 0;
    margin-left: 8px;
}

.packaging-printing-page .header .close .close-modal path {
    stroke: black;
}

.packaging-printing-page  table, .packaging-printing-page  th, .packaging-printing-page  td {
    border: 1px solid rgb(154 152 174 / 30%);
    border-collapse: collapse;
    padding: 10px;
}

.packaging-printing-page  table th {
    box-sizing: initial;
    background-color: #f0f0f3;
}

.container-max {
    max-width: 1200px;
    margin: 0 auto;
}

.section-gap {
    margin: 80px 0px;
}

.tr-bg-green {
    background-color: #28a745 !important;
    color: white;
}

.tr-bg-parts {
    background-color: #6c757d !important;
    color: white;
}

.tr-bg-pack {
    background-color: #007bff0f !important;
}

.tr-bg-parts .icon-down path {
    stroke: white;
}

.icon-down.show {
    transform: rotate(90deg) translateX(2px);
}

.amount {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 18px;
}

.packaging-printing-page .tr-collapse,
.packaging-printing-page .tr-collapse th {
    background-color: rgba(66, 98, 255, .1);
}

.icon-down {
    transform: rotate(-90deg) translateX(-3px);
    padding: 0px;
    width: 35px;
    margin: 0px 0px 0px 8px;
    cursor: pointer;
}

.section-data-project {
    column-count: 3;
    /*width: 820px;*/
    max-width: 100%;
    gap: 12px;
    /*background-color: #f2f2f2;*/
    /*padding: 10px;*/
    /*border: 1px solid rgb(154 152 174 / 30%);*/
}

.section-data-project .item {
    line-height: 28px;
    font-size: 16px;
}

.section-data-project .item strong {
    margin-right: 8px;
}
