.cabinet-wrapper {
    background-color: #f0f0f3;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
}

.content {
    display: flex;
    align-items: flex-start;
}

.header-container {
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    height: 7vh;
}

.cabinet-wrapper .sidebar {
    height: calc(100vh - 7vh - 48px);
    overflow-y: auto;
    min-width: 320px;
}

.cabinet-wrapper .main {
    height: calc(100vh - 7vh - 24px - calc(100vh - 100%));
}

.col {
    margin: 24px;
}

.logout {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logout-text {
    margin-left: 20px;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    white-space: nowrap;
}


.sidebar .item_nav svg {
    width: 20px;
    height: 20px;
}

.sidebar .item_nav.open {
    color: rgb(66, 98, 255);
    font-weight: 600;

}

.sidebar .item_nav.open svg:not(.icon-question) path {
    stroke: rgb(66, 98, 255);
}

.sidebar .item_nav:not(.open) svg:not(.icon-question) path {
    stroke: black;
}

.my-orders {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    cursor: pointer;
}

.my-orders-text {
    margin-left: 20px;
    color: #4262ff;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
}

.sidebar {
    flex: 1;
    background-color: #fff;
    height: 70vh;
    padding: 24px;
}

.main {
    flex: 5;
    background-color: #fff;
    height: 70vh;
}

.flex-cont {
    display: flex;
    flex-direction: column;
}

.main-header {
    background-color: #f0f0f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.header-title {
    font-size: 31px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
}

.create-project {
    background-color: #4262ff;
    color: #fff;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
}

.table_account_orders {
    width: 100%;
}

.table_account_orders td {
    text-align: center;
}

.cabinet-wrapper .header-title {
    margin: 15px 0;
}

.cabinet-wrapper .main {
    align-items: flex-start;
    margin-top: 0;
}

.table_account_orders td {
    padding: 25px 10px;
    border-bottom: 1px solid #EFF2F5;
}

.table_account_orders th {
    padding: 25px 10px;
    border-bottom: 1px solid #EFF2F5;
}

.table_account_orders {
    border-spacing: 0;
    border-collapse: separate;
}

.user-avatar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.user-name-block {
    padding-right: 15px;
}

.user-avatar svg {
    border: 1px solid #cccfd3;
    padding: 5px;
    border-radius: 50%;
    width: 28px;
    height: 28px;
}

.orders-list {
    height: calc(100% - 169px - 201px);
    padding-top: 150px;
    margin-top: -150px;
    background-color: #fff;
    border-bottom: 1px solid #EFF2F5;
}

.account_order_versions {
    position: absolute;
    /*padding: 9px;*/
    right: 70%;
    width: 280px;
    background-color: #fff;
    border: 1px solid #EFF2F5;
    top: -100%;
    border-radius: 5px;
}

.account_order_version_container {
    position: relative;
}

.account_version_item {
    padding: 9px 12px;
    border-bottom: 1px solid #EFF2F5;
}

.account_version_item a {
    color: black;
    cursor: pointer;
    text-decoration: none;
}

.account_order_version_container svg {
    width: 19px;
    height: 19px;
    opacity: 1;
    cursor: pointer;
}

.order-detail-infos {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 34px;
}

.order-detail-infos-column {
    width: 50%;
    padding: 0 15px;
    height: auto;
    overflow: hidden;
}

.order-detail-container-titles {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #050038;
    margin: 13px 0;
}

.order-detail-container-information-row-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #605D81;
    padding-right: 5px;
}

.order-detail-container-information-row-value {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #050038;
}

.order-detail-container-information-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
}

.order-details-main-container {
    width: 100%;
}

.products_sale_main_table table td {
    padding: 24px 10px;
    max-width: 220px;
}

.order-detail-container-calculate {
    padding: 0 15px;
}

.order-detail-container-buttons {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
}

.order-detail-container-buttons > button {
    margin-bottom: 15px;
    opacity: 1;
    cursor: pointer !important;
}

.cabinet-orders-filters-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.cabinet-orders-filters-item {
    width: 30%;
    margin: 10px 15px;
    opacity: 1;
}

.create-project-plus {
    display: none;
}

.logout-tb {
    position: absolute;
    top: 100%;
    right: 0;
    padding: 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #ededed;
    display: none;
}

.logout-tb svg {
    padding: 0px;
    border: none;
}

.cabinet-action__mobile .account_order_version_container {
    display: none;
}

.cabinet-action__mobile .see {
    display: none;
}


.container-change-status {
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 2;
}


.container-change-status .btn-dots {
    transform: rotate(90deg);
    margin-left: auto;
    position: absolute;
    right: -35px;
    cursor: pointer;
    top: 0px;

}

.container-change-status .container-status .title {
    margin-top: 0px;
    padding: 0px;
}

.bg-primary {
    background-color: #007bff !important;
}

.bg-info {
    background-color: #17a2b8 !important;
}

.bg-success {
    background-color: #28a745 !important;
}

.bg-danger {
    background-color: #dc3545 !important;
}

.list-status-project li {
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    padding: 5px;
    width: max-content;
    margin: 10px auto;
    cursor: pointer;
    max-width: 100%;
    font-weight: 400;

}

.container-change-status .container-status {
    min-width: 220px;
    margin-top: 25px;
    padding: 10px 5px;
    background: #ffffff; /* box-shadow: 0 0 0 10px; */
    box-shadow: 0px 0px 5px #9f9f9f;
    border: 1px solid #EFF2F5;
    z-index: 10;
    transform: translateY(calc(-100% + 20px));
    position: absolute;
    right: 0px;
}

.container-status * {
    font-size: 14px;
    background: white;
    z-index: 8888;
}

td.change-status-main {
    position: relative;
}

td.change-status-main ul {
    padding: 0px;
}

td.cabinet-action__mobile {
    /*display: flex;*/
    /*justify-content: center;*/
}

.cabinet-action__td-mob {
    display: none;
}

@media (max-width: 1024px) {
    .content {
        display: flex;
        align-items: center;
    }

}

@media (max-width: 1024px) {
    .orders-list {
        height: auto;
    }

    .cabinet-wrapper {
        /*height: auto;*/
        overflow-y: auto;
        min-height: 100vh;
    }

    .cabinet-wrapper .content {
        display: block;
    }

    .logout-tb {
        display: block;
    }

    .home .main {
        padding-bottom: 80px;
    }

    .main {
        height: calc(100vh - 203px);
    }

    .cabinet-wrapper .main {
        height: revert;
    }

    .create-project {
        display: none;
    }

    .order-detail-container-buttons .create-project {
        display: block;
    }

    .create-project-plus {
        display: block;
    }

    .cabinet-wrapper .sidebar {
        height: auto;
        padding: 7px 15px;
        flex-wrap: wrap;
        justify-content: flex-start;
        display: flex;
        align-items: center;
    }

    .sidebar .logout {
        display: none;
    }

    .sidebar .item_nav {
        margin: 8px 25px 8px 0px;
        /*text-align: center !important;*/
    }

    .header-title {
        font-size: 1.17rem;
    }

    .orders-list {
        overflow-x: auto;
    }

    .table_account_orders {
        width: max-content;
        min-width: 100%;
    }
}

@media (max-width: 576px) {
    .order-detail-infos {
        flex-direction: column;
    }

    .order-detail-infos-column {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .container-change-status .container-status {
        /*transform: translateY(20px);*/
    }

    .cabinet-action__mobile .see {
        display: block;
    }

    td.cabinet-action__mobile {
        max-width: 150px;
        width: 150px;
    }

    .cabinet-action__td-mob {
        display: revert;
    }

    .cabinet-action__td {
        display: none;
    }

    .cabinet-action__mobile {
        /*display: flex;*/
        /*align-items: center;*/
    }

    .cabinet-action__mobile .see {
        position: relative;
        top: 7px;
        left: 10px;

    }

    .cabinet-action__mobile .account_order_version_container {
        display: inline-block;
        margin-left: 7px;
    }

    .header-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
    }

    .cabinet-orders-filters-item {
        width: calc(50% - 20px);
        margin: 0px 10px 5px 10px;
        opacity: 1;
    }

}

@media (max-width: 375px) {
    .cabinet-orders-filters-container {
        max-height: 240px;
        overflow-y: auto;
    }

    .cabinet-orders-filters-item {
        width: 100%;
    }

    .user-name-block {
        max-width: 75px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 0;
        padding-right: 5px;
    }
}

.btn.btn__action-list {
    border: none;
    margin: 0px !important;
    line-height: initial;
    padding: 8px;
    width: max-content;
}

.btn-reverse {
    background: white;
    color: #007bff;
}

table.table_account_orders tr:first-child .container-change-status .container-status {
    /*transform: translateY(-50px);*/
}

@media (min-width: 1025px) {
    .cabinet-wrapper .col.main {
        width: calc(100% - 350px);
        flex: calc(100% - 350px);
        max-width: calc(100% - 350px);
    }
}

.info-icon {
    width: 25px;
    height: 25px;
    position: relative;
}

.info-icon:before {
    content: 'i';
    font-style: italic;
    font-size: 22px;
    color: white;
    width: 25px;
    height: 25px;
    background: #4262ff;
    display: block;
    text-align: center;
    line-height: 21px;
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: 0px;
}

.stock-order_icon svg {
    height: 24px;
    width: 24px;
}

.stock-order_icon {
    cursor: pointer;
    margin: 0px 0px;
    text-align: center;
}

.col.main .settings-section {
    padding: 20px 15px;
}

.sidebar .item_nav {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    cursor: pointer;
    text-align: left;
}

.sidebar .item_nav svg {
    margin-right: 8px;
}

.sidebar .item_nav span:first-child {
    width: 10px;
    height: 10px;
    flex: 0 0 10px;
    overflow: hidden;
    background: #0000008a;
    border-radius: 50%;
    margin-right: 15px;
    margin-left: 5px;
}

.open.item_nav span:first-child {
    background: #4262ff;
}

.payment-block {
    margin: 25px 0;
    background: #4262ff;
    padding: 10px 5px;
    border-radius: 8px;
}

.payment-block .btn.btn-reverse {
    display: block;
    margin: 8px auto 0 auto;
    padding: 12px 8px !important;
    font-size: 12px !important;
    text-transform: uppercase;
    font-weight: 500;
    width: max-content;
    outline: none;
    border: none;
}

.payment-block p {
    margin: 0px 0px 15px 0px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;

}

ul.payment-list {
    list-style: circle;
    padding: 0px 0px 0px 20px;
    font-size: 14px;
    color: white;
}

ul.payment-list li {
    list-style: circle;
    margin-bottom: 8px;
    color: white;
}


.card-furniture__actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.card-furniture__actions a {
    display: block;
    margin-left: 6px;
}

.card-furniture__actions a svg {
    width: 22px;
    height: 22px;
}

.card-furniture-wrap .card-img svg {
    width: 100%;
    height: 100%;
}

.carousel-root {
    height: 100%;
}

.carousel .control-dots .dot {
    background: white;
    opacity: 1 !important;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
    background: white;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    background: #4262ff;
}

.carousel .slider-wrapper.axis-horizontal .slider,
.carousel-root .carousel.carousel-slider .slider-wrapper .slide > div > img,
.carousel-root .carousel.carousel-slider .slider-wrapper .slide > div,
.carousel-root .carousel.carousel-slider .slider-wrapper .slide,
.carousel-root .carousel.carousel-slider .slider-wrapper,
.carousel-root .carousel.carousel-slider {
    height: 100%;
}

.info-user-data {
    margin-top: 25px;
    border: 1px solid #d9d8e1;
    border-radius: 6px;
    box-shadow: 0px 0px 5px 0px #eeee;
    padding: 5px 10px;
}

.info-user-data ul {
    max-width: 100%;
    width: max-content;
    padding: 0px;
    margin: 0px;
}

.info-user-data .label {
    padding: 3px 0px;

}

@media (max-width: 1024px) {
    .info-user-data {
        margin-top: 15px;
        border: 0px;
        box-shadow: 0 0 0 transparent;
        padding: 0px;
        margin-right: auto;
        /*display: none;*/
    }
}

.cabinet__static-page {
    margin-top: 25px;
}

.cabinet__static-page .login-header-links-container {
    justify-content: flex-start;
    z-index: 3;
    flex-direction: column;
}

.cabinet__static-page .login-header-links-container a {
    margin-left: 0px;

}

.cabinet-logo {
    max-width: 115px;
}
@media (min-width: 1024px) {
    .cabinet-wrapper .sidebar{
        display: flex;
        flex-direction: column;
    }
    .cabinet-logo {
        margin-top: auto;

    }
}
@media (max-width: 1024px) {
    .cabinet-logo {
        display: none;
    }

    .cabinet__static-page {
        margin-top: 0px;
        order: 2;
        flex: 0 0 100%;
    }

    .cabinet__static-page .login-header-links-container {
        position: relative;
        padding: 0px;
        border: 0px;
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        margin-top: 0px;
        display: flex;

    }

    .cabinet__static-page .login-header-links-container a {
        margin-left: 0px;

    }

    .cabinet__static-page .burger-menu-wrap button.burger-btn {
        margin: 0px 0px 0px auto;
    }

    .info-user-data {
        order: 3;
    }

    .payment-block {
        order: 4;
    }
}

.account_version_item .btn {
    display: block;
    padding: 5px !important;
    margin: 4px;
    font-size: 13px !important;
}



.client-firms-container .card_body {
    max-height: 350px;
    overflow-y: auto;
}


.client-firms-container  .current {
    color: #3586FF;
    font-weight: 500;
}

.client-firms-container  .county{
    color: #999;
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 5px;
}
.client-firms-container {
    display: grid;
    grid-template-columns: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    grid-gap: 25px;
    gap: 25px;
    margin: 35px 0;
}

@media (min-width: 768px) {
    .client-firms-container {
        max-width: 992px;
        grid-template-columns: 48% 48%;
    }
}



/*NEWS*/
.section-news h5{
    margin: 0 0 8px 0;
}
.section-news ul li{
    list-style: circle;

}
.section-news ul{
    list-style: circle;
    padding-left: 15px;
}
.news-wrap .new-item {
    margin-bottom: 22px;
}

.news-wrap .new-item .card_header .icon-arrow  {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    cursor: pointer;
}
.news-wrap .new-item .card_header .arrow-top {
    transform: translateY(-50%) rotate(90deg);
}
.news-wrap .new-item .card_header .time{
    font-size: 13px;
    color: var(--gray-violet);
    font-weight: 400;
}
.news-wrap .new-item .card_header  .title{
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 25px;
    width: 100%;
}
.news-wrap .new-item .card_header {
    position: relative;
    padding: 15px 45px 15px 15px;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;

}
.news-wrap .new-item .card_body{
    padding: 15px;
}
.new-item-section{
    margin-top: 25px;
}
.new-item-section h5{
    font-size: 16px;
}
@media (min-width: 1200px) {
    .news-wrap .new-item .card_header {
        padding: 20px 45px 20px 20px;
        font-size: 22px;
        font-weight: 600;
    }
    .news-wrap .new-item .card_body{
        padding: 25px;
    }
    .new-item-section h5{
        font-size: 20px;
    }
}

.social-links .social-media_link {
    display: flex;
    align-items: center;
    max-width: 50px;
}

.social-links .base64_qr_link {
    display: flex;
    align-items: center;
    max-width: 120px;
}
.social-links .item{
    display: flex;
    align-items: flex-start;
    gap: 15px;
}
.new-item.card .content {
    display: block !important;
}

.btn-sketch{
    border-color: var(--blue);
    border-width: 1px;
    border-radius: 3px;
    border-style: solid;
    background-color: transparent;
    padding: 0 12px;
}