.modal-bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
    /*z-index: 50;*/
}

@media (min-width: 768px) {
    .modal-xl .modal-wrapper {
        width: 768px;
    }

}

.modal-wrapper {
    width: 654px;
    background-color: #fff;
    color: black;
    position: relative;
    z-index: 51;
    max-height: 95vh;
    overflow-y: hidden;
    border-radius: 20px;
}

.modal-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #050038;
    color: #fff;
    font-size: 18px;
    position: relative;
    top: 0;
    padding: 0 12px 0 12px;
    border: 1px solid #050038;
    gap: 10px;
}

.modal-body {
    padding: 25px;
    max-height: calc(100% - 100px);
    overflow-y: auto;

}

@media (min-width: 768px) {
    .modal-wrapper{
        max-width: calc(100% - 30px);
    }
    .modal-body {
        max-height: calc(100vh - 200px);

    }
}

.close-modal {
    cursor: pointer;
    margin-left: auto;
}

.modal-header h3 {
    line-height: 1;
}

.modal-wrapper .container {
    max-height: calc(95vh - 87px);
    overflow-x: hidden;
    overflow-y: scroll;
    align-items: flex-start;
    padding: 24px 12px;
}

.settings-modal .modal-wrapper {
    overflow-x: auto;
}

@media (min-width: 769px) {
    .modal-header .goback-modal {
        display: none;
    }
}

@media (max-width: 768px) {
    .modal-wrapper {
        position: fixed;
        width: 100vw;
        min-height: 100vh;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-radius: 0px;
        height: calc(100vh - calc(100vh - 100%));
        max-height: calc(100vh - calc(100vh - 100%));
        max-width: 100%;

    }

    .modal-wrapper .container {
        padding-top: 0px;
        max-height: calc(100vh - 87px - calc(100vh - 100%));
        overflow-y: auto;
        height: -webkit-fill-available;
    }

    .modal-header .close-modal {
        display: none;
    }

    .modal-header {
        background-color: transparent;
        border: none;
        justify-content: center;
        position: relative;
        padding: 24px;
    }

    .modal-header h3 {
        color: rgba(5, 0, 56, 1);
        text-align: center;
    }

    .goback-modal {
        /*position: absolute;*/
        /*left: 20px;*/
        /*top: 50%;*/
        /*transform: translateY(calc(-50% + 0px));*/
    }
}

.postformnig-list {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fill, calc(50% - 10px));
    justify-content: space-between;
    grid-gap: 10px;
}

.postformnig-list .item {
    border: 1px solid #D6D6DF;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.postformnig-list .item .title {
    font-size: 14px;
    text-align: center;
    padding: 10px;
    margin: 0;

}

.postformnig-list .item img {
    transform: scale(0.7);
    transition: transform 0.03s ease-in-out;
}

.postformnig-list .item:hover img {
    transform: scale(1.1);
}

.postformnig-list .item.selected {
    /* background-color: #D6D6DF; */
    border-color: #007bff;
}

.postformin-form {
    /*margin-top: 15px;*/
    /*padding: 15px;*/
}

.postformin-form .form-field-images .images img {
    margin-left: 8px;
    margin-top: 5px;
    cursor: pointer;
}

.postformin-form .form-field-images {
    display: flex;
    justify-content: space-between;
}

.postformin-form {
    display: grid;
    gap: 20px;
}

@media (min-width: 576px) {
    .postformin-form {
        grid-template-columns: repeat(2, calc(50% - 10px));
    }

    .postformin-form .postformin-action-wraper-btn {
        grid-column: 1/2;
        grid-column: span 2;
    }

    .postformnig-list {
        /*grid-template-columns: repeat(auto-fill, calc(33.3% - 10px));*/

    }
}

.modal-container-postforming {
    min-height: 200px;
}

.postformnig-action-open-template svg {
    transform: rotate(0deg) !important;
    filter: brightness(0) saturate(100%) invert(39%) sepia(63%) saturate(4759%) hue-rotate(221deg) brightness(99%) contrast(105%);
}