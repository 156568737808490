
.nightstands-container {
    display: flex;
    justify-content: space-between;
}

.aside {
    padding: 0 24px;
    flex: 1;
}

.main_content {
    flex: 5;
    flex-direction: column;
}

.aside-nav-title {
    font-size: 17px;
    color: #050038;
    font-weight: 400;
}

.search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-bar-field {
    width: 85%;
}

.search-bar-field input {
    display: inline-block;
    padding: 8px;
    width: 100%;
    border-radius: 20px;
    outline: none;
    border: 1px solid #cdccd7;
}

.search-btn {
    border-radius: 4px;
    border: 1px solid #4262ff;
    padding: 4px 8px;
    cursor: pointer;
}

.search-btn-text {
    color: #4262ff;
}

.fiters-container {
    position: absolute;
    top: 60px;
    right: 24px;
    background-color: #fff;
}

.nightstands-content-title {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    margin-bottom: 6px;
}

.nightstands-content-subtitle {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.card-container {
    display: flex;
    justify-content: space-between;
}

ul,
li {
    list-style: none;
}

.links {
    text-decoration: none;
    /*font-family: Formular;*/
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: #050038;
    word-wrap: break-word;
}

.materials_nav_item_childs {
    margin-left: 15px;
}

.search-bar-field {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.search-bar-field svg {
    position: absolute;
    left: 5px;
}

.search-bar-field input {
    padding-left: 34px;
}

.mob-detailing-btn,
.material-menu-btn .folder-icon,
.search-bar .back-go {
    display: none;
}

.aside-navigation {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background: white;
    padding: 20px;
    width: 460px;
    box-shadow: 0 0 15px #ccc;
    z-index: 10;

}

.aside-navigate-materials {
    width: 320px;
    max-width: 100%;
}

@media (min-width: 1200px) {
    .aside-navigate-materials {
        display: none;
    }
}

.nightstands-container .aside-navigation .aside-nav-title {
    margin: 0 0 15px 0;
}

.navigation-material__tabled .navigation-menu {
    max-height: 100vh;
    overflow-y: auto;
    height: -webkit-fill-available;
}

.navigation-material__tabled .close {
    text-align: right;
}

.navigation-material__tabled .close svg path {
    stroke: rgb(5, 0, 56) !important;
}

.navigation-material__tabled > .go-back {
    display: none;
    align-items: center;
    margin-bottom: 25px;
}

.nightstands-container .navigation-material__tabled .materials_nav_item_name.active {
    left: 0;
}

.search-bar.search-bar__materials .navigation-material__tabled {
    height: 100%;
}

@media (max-width: 1200px) {

}


@media (max-width: 1200px) {
    .mob-detailing-btn {
        display: flex;
    }

    .search-bar .back-go {
        display: block;
        margin-right: 15px;
    }

    .nightstands-container {
        height: inherit;
    }

    .nightstands-container .aside {
        display: none;
    }

    .material-menu-btn .folder-icon {
        width: 20px;
        display: block;
        margin-left: 15px;
    }

    .search-bar .search-btn svg,
    .search-bar .search-btn svg path {
        stroke: rgb(5, 0, 56) !important;
    }

    .search-bar .search-btn .search-btn-text,
    .search-bar .search-btn {
        border: none;
        font-size: 0 !important;
    }
}

@media (max-width: 768px) {
    .aside-navigation {
        width: 100%;
    }

    .navigation-material__tabled .close {
        display: none;
    }

    .navigation-material__tabled .go-back {
        display: flex;
    }

    .nightstands-container .card-container .card-materials {
        width: 100%;
        background: rgba(239, 242, 245, 1);
        margin-right: 0;
        padding: 12px;
    }

    .nightstands-container .card-container .card-materials .card-body .card-img {
        width: 35%;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
    }

    .nightstands-container .card-container .card-materials .card-body {
        position: relative;
        padding-left: 40%;
    }

    .card-materials .add-to-card {
        width: calc(100% - 40%);
        margin-left: auto;
        margin-top: 8px;
    }

    .card-materials .card-sizes {
        margin: 4px;
    }

    .card-materials .card-title {
        margin-bottom: 4px;
    }

    .card-materials .card-img img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .card-wrapper.card-materials.added:before {
        left: calc(50% - 13px);
        top: calc(50% - 16px);
        width: 26px;
        height: 100px;
        transform: translateY(calc(-50% - 0px)) rotate(45deg);
    }

}

@media (max-width: 568px) {
    .card-materials .add-to-card {
        width: calc(100% - 90px);
    }

    .nightstands-container .card-container .card-materials .card-body {
        padding-left: 90px;
    }

    .nightstands-container .card-container .card-materials .card-body .card-img {
        width: 80px;
        height: 80px;
        min-width: 80px;
    }

}

@media (max-width: 375px) {
    .card-materials .add-to-card {
        width: 100%;
    }
}

.detail-drawing-modal .modal-wrapper {
    width: 100%;
    max-width: 1020px;
}

.detail-drawing-modal .detail-drawing-iframe.img-container {
    margin: 0 !important;
    height: 80vh;
    width: 100%;
}

.detail-drawing-modal .detail-drawing-iframe.img-container iframe {
    zoom: 0.7;
}

.template-wrapper-modal {
    gap: 24px;
}

.template-wrapper-modal .aside {
    flex: 0 0 280px;
    padding: 0;
}

.template-wrapper-modal .materials_nav_item_name.active {
    left: 0;
}

.template-wrapper-modal .materials_nav_item_name.active svg {
    filter: brightness(0) saturate(100%) invert(95%) sepia(0%) saturate(7469%) hue-rotate(115deg) brightness(108%) contrast(105%);
}