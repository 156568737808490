.cls-1, .cls-2 {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.cls-1 {
    stroke-width: 6px;
}

.cls-2 {
    stroke-width: 2px;
}
