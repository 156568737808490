.footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: #f0f0f3;
}
.questions {
  border: 2px solid #050038;
  border-radius: 20px;
  color: #050038;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 20px;
  text-align: center;
}
.interface-old-back-link {
  color: rgb(66, 98, 255);
  text-decoration: none;
  font-size: 14px;
}
.success_save_order {
  background: green;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: 10px;
}
.success_save_order svg{
  animation-name: example;
  animation-duration: 250ms;
  animation-iteration-count: infinite;
}
.success_save_order path {
  stroke: white;
}
/* The animation code */
@keyframes example {
  from {transform: scale(0.8)}
  to {transform: scale(1.1)}
}


.footer-danger {
  max-height: 30px;
}