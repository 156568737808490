.nightstands-wrapper {
  padding: 24px;
}

.nightstands-container {
  display: flex;
  justify-content: space-between;
}

.aside {
  padding: 0 24px;
  flex: 1;
}
.main_content {
  flex: 5;
  flex-direction: column;
}

.aside-nav-title {
  font-size: 17px;
  color: #050038;
  font-weight: 400;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-bar-field {
  width: 85%;
}
.search-bar-field input {
  display: inline-block;
  padding: 8px;
  width: 100%;
  border-radius: 20px;
  outline: none;
  border: 1px solid #cdccd7;
}

.search-btn {
  border-radius: 4px;
  border: 1px solid #4262ff;
  padding: 4px 8px;
  cursor: pointer;
}

.search-btn-text {
  color: #4262ff;
}

.fiters-container {
  position: absolute;
  top: 60px;
  right: 24px;
  background-color: #fff;
  /* display: flex;
  flex-direction: column; */
}
.filters-container-head{
  display: none;;
}
.filters-container-header__title{
  color: rgba(5, 0, 56, 1);
  font-size: 24px;
  font-weight: 500;
  margin-top: 35px;
}

.nightstands-content-title {
  /*font-family: Formular;*/
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  margin-bottom: 6px;
}
.nightstands-content-subtitle {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.card-container {
  display: flex;
  justify-content: space-between;
}

ul,
li {
  list-style: none;
}
.links {
  text-decoration: none;
  /*font-family: Formular;*/
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #050038;
}

.materials_nav_item_childs {
  margin-left: 15px;
}
.search-bar-field {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.search-bar-field svg {
  position: absolute;
  left: 5px;
}
.search-bar-field input {
  padding-left: 34px;
}
/*.nightstands-container .goback {*/
/*  display: none;*/
/*}*/

@media (min-width:1200px) {
  .nightstands-container .goback {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 26px;
    color: #050038;
    order: 1;
    margin: 0px 6px;
    display: flex;
  }
}


.nightstands-container .goback-text {
  margin-left: 8px;
  cursor: pointer;

}

.nightstands-container .goback > div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.nightstands-container .goback svg {
  width: 21px;
  height: 30px;

  /*margin-top: 5px;*/
}
.nightstands-container  .goback{
  display: none;
}

@media (min-width: 1200px) {
  .nightstands-container  .goback{
    display: flex;
  }

}

.nightstands-container .materials_nav_item_name {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  color: #050038;
  margin: 0;
  margin-bottom: 19px;
  cursor: pointer;
}
.nightstands-container .materials_nav_item_childs {
  margin-left: 31px;
}
.nightstands-container .aside-nav-title {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 26px;
  color: #050038;
  margin: 0;
  margin-bottom: 19px;
  margin-top: 41px;
  cursor: pointer;
}
.nightstands-container .materials_nav_item_name.active {
  padding: 4px 12px;
  background: #050038;
  border-radius: 500px;
  color: #FFFFFF;
  position: relative;
  left: -13px;
}
.nightstands-container .card-img {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.nightstands-container .card-img svg {
  width: 105px;
  height: 105px;
  opacity: 0.91;
}
.nightstands-container .card-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
}
.nightstands-container .card-container .card-materials {
  margin-right: 19px;
  margin-bottom: 19px;
}
.nightstands-container .search-bar-field svg {
  left: 10px;
}
.nightstands-container .search-bar-field input {
  padding-left: 41px;
}
.nightstands-container .fiters-container {
  top: 69px;
  z-index: 15;
  padding: 15px;
  border: 1px solid lightgray;
}
.main_modal_pages_search_form {
  width: 100%;
}

.fiters-container .filters-container-head  .back-arrow{
  display: none;
}
@media (max-width: 1024px) {
  .search-bar__materials {
    margin-bottom: 25px;
  }
}

@media (max-width: 768px) {
  .fiters-container .filters-container-head{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;

  }
  .fiters-container .filters-container-head  .back-arrow{
    display: block;
    left: 0;
    position: absolute;
  }

  .fiters-container .filters-container-head .go-back{
    display: none;
  }
  .fiters-container .filters-container-head .filters-container-header__title{
    margin: 0px;
  }
  .nightstands-container .fiters-container{
    position: fixed;
    z-index: 999999;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .nightstand-filter {
    display: grid!important;
    grid-template-columns: repeat(2, 45%);
    justify-content: space-between;
  }
  .fiters-container .filter-select,
  .fiters-container .filters-footer {
    grid-column: 1/3;
  }
  .fiters-container .filters-footer{
    margin-top: 25px;
    text-align: center;
  }
  .fiters-container .nightstand-filter{
    width: 100%;
  }
}

.available_on_stock {
  text-align: center;
  background: #f2f2f2;
  padding: 9px;
  margin: 19px 0px 0px 0px;
  font-weight: 500;
}