.container-stock .modal-wrapper{
    width: 750px;
}
table.stock-table td, table.stock-table tr {
    padding: 5px;
    max-width: 220px;
}

table.stock-table thead {
    background: #f0f0f3;
}

table.stock-table tbody tr:nth-child(even) {
    background-color: #f5f5f5;
}

table.stock-table tbody tr:nth-child(even) {
}
.container-stock .btn {
    padding: 4px;
    margin-top: 4px;
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
}
.stock-wrap {
    padding: 12px 10px;
    background: white;
    /*box-shadow: 0 0 5px #cccccc;*/
    margin-bottom: 25px;
    border-radius: 8px;
    margin-right: 10px;
    border: 1px solid  #cdccd7;
}

.stock__header {
    position: relative;

    /*max-width: calc(100% - 15px);*/
    cursor:pointer;
}
.stock__header span{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
    width: calc(100% - 25px);
display: block;
}
.stock__header:before{
    content: "";
    position: absolute;
    right: 4px;
    top: calc(50% - 6px);
    display: block;
    width: 8px;
    height: 8px;
    transform: rotate(46deg);
    border-width: 1px;
    border-style: solid ;
    border-color: black;
    border-top-color: transparent;
    border-left-color: transparent;
    transition: border-color 0.25ms;

}
.stock__body{
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid  #cdccd7;
}
.stock__header.open{

}
.stock__header.open:before{
    border-top-color: black;
    border-left-color: black;
    border-right-color: transparent;
    border-bottom-color: transparent;
}
.modal-bg.remain-modal .modal-wrapper {
    width: 1000px;
    max-width: 100%;
}
.modal-bg.remain-modal .modal-wrapper .inst__bore-form{
    padding: 40px 20px;
}

@media (max-width: 576px) {
    .modal-bg.remain-modal .modal-wrapper .inst__bore-form{
        padding: 20px 15px;
    }
}
@media (max-width: 768px) {
    .container-stock .modal-wrapper{
        width: 100%;
    }
    .stock__body{
        overflow-x: auto;
    }
    .stock-table{
        min-width: 100%;
        width: max-content;
    }
}

table.stock-table th {
    padding: 5px 10px;
}

