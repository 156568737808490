.products-container {
  padding: 24px;
}

td,
tr {
  text-align: center;
  padding: 24px 0;
}

.add-container-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.col-sm-4 {
  width: 450px;
}
.col-sm-1 {
  padding-top: 24px;
}

.rows {
  width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-product {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  background-color: #fff;
  color: #4262ff;
  border-radius: 4px;
  border: 1px solid #4262ff;
  cursor: pointer;
}

.main-wares-table th {
  border-bottom: 1px solid #f0f0f3;
  padding-bottom: 24px;
}
.main-wares-table td {
  border-bottom: 1px solid #f0f0f3;
  vertical-align: middle;
}
.main_add_products_container {
  margin-top: 54px;
}
.main_add_products_container h3 {
  margin-bottom: 0;
  border-bottom: 1px solid #f0f0f3;
  padding-bottom: 15px;
}


@media (max-width: 1024px) {
    .main_add_products_container .add-container-title,
    .main_add_products_container {
        margin-top: 0px;
    }

    .main_add_products_container .rows > div{
        margin: 10px 0px 10px 0px;
        padding: 0px;
    }
    .main_add_products_container .rows{
        flex-wrap: wrap;
        width: 100%;
        align-items: flex-end;
    }
}
@media (max-width: 768px) {
    td, tr{
        padding: 15px 0px;
    }
    .main-wares-table .form-field input{
        height: auto;
        padding: 8px;
    }
    .main-wares-table th,
    .main-wares-table td {
        padding-right: 10px;
    }
}
