.form-input-sm,
.form-input {
    width: 100%;
    border-radius: 3px;
    outline: none;
    border: 1px solid #d9d8e1;
    padding: 13px;
    height: 51px;
}

.form-input-sm {
    padding: 5px 8px;
    height: 35px;
    font-size: 14px;
}

.label {
    display: block;
    padding: 10px 0;
    color: #605d81;
    font-family: "Open Sans" sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.label.label-sm {
    font-size: 13px;
}

.form-field.error input,
.form-field.error select {
    border-color: var(--red);
}

.form-field {
    width: 100%;
}

input.form-input[type="radio"] {
    width: 20px;
}

.field-radio.form-field {
    display: flex;
    align-items: center;
}

.field-radio.form-field input[type="radio"] {
    margin-left: 15px;
}

.form-input.phone-code-disabled {
    width: 80px;
}

.form-group-rx-md-03 {
    align-items: flex-end;
}

@media (min-width: 540px) {
    .form-group-rx-sm-02 {
        display: grid;
        grid-template-columns:  repeat(auto-fill, calc(100% / 2 - 10px));
        justify-content: space-between;
    }
}

@media (min-width: 768px) {
    .form-group-rx-md-03 {
        display: grid;
        grid-template-columns: repeat(auto-fill, calc(100% / 3 - 10px));
        justify-content: space-between;
    }
}