.warning-modal{
    padding: 20px;
    text-align: center;
}
.warning-modal .message{
    font-size: 18px;
    color: #605d81;
}
.warning-modal__btns{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.warning-modal__btns button{
    width: max-content;
    /*min-width: 120px;*/
    margin: 5px;
    padding: 12px 15px;
    outline: none;
    box-shadow: 0 0 0 transparent;
    border: none;
}
.btn-danger{
    background: #dc3545 !important;
    border-color: #dc3545 !important;
}
@media (max-width: 768px) {
    .modal-header h3 {
        font-size: 18px;
        margin: 0px;
    }
}