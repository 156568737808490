.add-client-material {
    width: 100%;
}
.client-materials-adds-main-container {
    width: 100%;
}
.add-materials-parts-pieces-form {
}
.add-materials-parts-pieces-form .form-input.submit {
    background-color: transparent;
    color: #4262FF;
    border: 1px solid #4262FF;
    margin-top: 15px;
}

.material_band_modal_create_header{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
}

.material_band_modal_create_header h3{
    margin: 0;
}