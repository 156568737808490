.logo-st0{fill:black;stroke:#000000;}
.logo-st1{fill:#00DCF9;stroke:#000000;}
.logo-st2{fill:black;stroke:#FF0000;}
.logo-st3{fill:none;stroke:#FF0000;stroke-miterlimit:10;}
.logo-st4{fill:none;stroke:#FF0808;stroke-miterlimit:10;}
.logo-st5{fill:#FFFFFF;stroke:#000000;}
.logo-st6{fill:none;stroke:#000000;stroke-miterlimit:10;}
.link.main-logo{
    display: block;
}
.main-logo{
    transform: scale(1.2) translateY(0px);
}
.new-logo-st0{fill:#1D1D1B;}
.new-logo-st1{display:none;}