.lock-window {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000b3;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.lock-window-modal {
    background: white;
    padding: 15px 15px;
    max-width: 100%;
    border-radius: 3px;
    /* width: 280px; */
    font-size: 18px;
    font-weight: 500;
}
.body-disabled{
    pointer-events: none;
    position: relative;
    z-index: 0;
}