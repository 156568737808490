
.info-pages-main-wrapper .info-pages-main-content {
    margin: 15px auto;
    max-width: 1000px;
    padding: 0px 15px 30px 15px;
}

.info-pages-main-wrapper .header {
    margin-bottom: 0;
}

.info-pages-main-content ul {
    padding-left: 15px;
}

.info-pages-main-content ul > li {
    margin: 10px 0;
    font-size: 15px;
    line-height: 22px;
}

.info-pages-main-content p {
    font-size: 15px;
    line-height: 22px;
    margin: 10px 0;
}

body .info-pages-main-content a {
    color: blue;
    font-weight: 500;
    letter-spacing: 0.3px;
}

@media (max-width: 768px) {
    body .info-pages-main-wrapper * {
        font-size: 15px;
        line-height: 22px;
    }

    .info-pages-main-wrapper h1 {
        font-size: 22px;
    }

    .info-pages-main-wrapper h2,
    .info-pages-main-wrapper h3 {
        font-size: 20px;
    }

    .info-pages-main-wrapper h4,
    .info-pages-main-wrapper h5 {
        font-size: 18px;
    }

}