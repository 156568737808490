.batch-machining-modal {
    width: 100%;
}

@media (min-width: 768px) {
    .field-group {
        display: grid;
        grid-template-columns: repeat(2, 48%);
        justify-content: space-between;
    }
    .batch-machining-modal  .btn{
        padding: 15px 2px !important;
        font-size: 16px !important;
    }
}
.batch-machining-modal  .btn{
    margin-top: 25px;
}
select[multiple]{
    min-height: 89px;
}
button.file-manager-btn__add.btn{
    width: max-content;
    max-width: 100%;
}

.group-operation-parts .item{
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 8px 0px;
}

.batch-machining-header {
    border-bottom: 1px solid #c7c7c7;
    margin-bottom: 15px;
}
.group-operation-parts  .form-input-checkbox-label:before{
    border-color: #222222;
}
.group-operation-parts  .radio{
    display: flex;
    align-items: center;

}
.group-operation-parts  .radio label{
    margin: 0px 8px 0px 3px;
}