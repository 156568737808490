.ordering-wrapper {
  background-color: #f3f4f8;
}

.ordering-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background-color: #fff;
  margin-bottom: 20px;
}
.ordering-header-title {
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  text-align: center;
  flex: 20;
}

.ordering-form {
  padding: 24px;
  background-color: #fff;
}
.ordering-footer {
  margin-top: 24px;
  background-color: #fff;
  padding: 24px;
}
.confirm-order-container-main .ordering-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
}
.confirm-order-container-main .ordering-wrapper form {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
}
.confirm-order-container-main {
  background-color: #f3f4f8;
}
.confirm-order-container-main .ordering-wrapper .ordering-header {
  flex-flow: row-reverse;
}

.confirm-order-container-main .ordering-wrapper .order {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.confirm-order-container-main .ordering-wrapper .order .form-field {
  max-width: 351px;
}
.order-form-method-delivery-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.confirm-order-container-main .form-field-phone {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.confirm-order-container-main .form-field-phone select {
  width: 145px;
}
textarea.form-input {
  min-height: 91px;
}
.order-confirm-success h1 {
  text-align: center;
  width: 100%;
  color: #4262ff;
}
.ordering-wrapper.order-confirm-success {
  justify-content: center;
}
.ordering-wrapper.order-confirm-success .order-confirm-success-content {
  background-color: #ffffff;
  padding: 34px;
  opacity: 1;
}
.ordering-wrapper.order-confirm-success .order-confirm-success-content {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}
.order-confirm-success .ordering-footer .total-content {
  justify-content: center;
}
.tab-goback{
  display: none;
}
@media (max-width: 768px) {
  .ordering-footer{
    margin-top: 0px;
    padding: 14px 0px;
  }
  .ordering-header .order .form-field{
    padding: 5px;
    height: auto;
    max-width: 100%;
    width: auto;
  }
}
@media (max-width: 1199px) {
  .ordering-header{
    position: relative;
    padding: 15px 0px 15px 20px;
  }

  .tab-goback{
    display: block;
    position: absolute;
    top: calc(50% - 5px);
    left: 15px;
  }
  .goback{
    display: none;
  }

}

form#order-confirm-form-main .col-sm-4 {
  width: 550px;
  max-width: 100%;
}



.ordering-footer .content-amount{
  font-size: 18px;
  font-weight: 600;
  color: #050038;
}
.content-amount.content-amount-without-vat{
  font-size: 22px;
}