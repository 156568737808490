.products_sale_main_table th {
    padding-bottom: 24px;
    border-bottom: 1px solid #f0f0f3;
}
.products_sale_main_table td {
    padding-bottom: 24px;
    border-bottom: 1px solid #f0f0f3;
}
.products_sale_main_table h3 {
    text-align: left;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
}
.products_sale_main_table h3 svg {
    margin-right: 9px;
}
@media (max-width: 768px) {
    .products_sale_main_table th {
        padding-right: 10px;
    }
}
