@import "../Materials/Materials.css";
@import "../Templates/Templates.css";


.plane_operations_item_name {
    padding: 8px 8px;
    font-size: 14px;
    line-height: 16px;
}

.operations-plane-choice_list .materials_nav_item.plane_operations_nav_item {
    flex: 0 0 calc(100% / 1);
    border: 1px solid var(--border);
    box-sizing: content-box;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
}

.plane_operations_item_image {
    width: 59px;
}

.plane-operations-modal .table-wrapper {
    width: 100%;
    padding: 0;
}

.plane-operations-modal .table-wrapper td {
    border-bottom: 1px solid #f0f0f3;
}

.plane-operations-modal .table-wrapper th {
    border-bottom: 1px solid #f0f0f3;
    padding-bottom: 19px;
}

.plane-operations-modal .form-input-checkbox:checked + span:after {
    left: calc(50% - 9px);
}

.plane-operations-modal .form-input-checkbox-label:before {
    border: 1px solid lightgray;
}


.plane-operation__search {
    display: none;
}

@media (max-width: 1200px) {


    .nightstands-wrapper.modal-page.plane-operations-modal .nightstands-container .main_content .search-bar {
        display: none;
    }

    .plane-operation__search {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .plane-operation__search .search-bar {
        flex: 1 1 auto;
        margin-left: 15px;
    }


    .plane_operations_nav_item .plane_operations_item_image {
        width: 45px;
    }

    .plane_operations_item_name {
        /*padding: 15px;*/
    }


    .nightstands-wrapper.modal-page.plane-operations-modal .nightstands-container .table-wrapper {
        padding: 0;
    }
}

@media (max-width: 768px) {
    .nightstands-wrapper.modal-page.plane-operations-modal .nightstands-wrapper {
        padding: 24px 15px;
    }

}

.nightstands-wrapper.modal-page.plane-operations-modal .nightstands-content-panel {
    flex-wrap: wrap;
    /*justify-content: flex-end;*/
    gap: 10px;
    display: flex;
}

.nightstands-wrapper.modal-page.plane-operations-modal .nightstands-content-panel .nightstands-content-title {
    margin-right: auto !important;
}

.nightstands-wrapper.modal-page.plane-operations-modal .nightstands-content-panel .search-bar-field input {
    padding: 15px 8px 15px 41px;
}

.nightstands-wrapper.modal-page.plane-operations-modal .nightstands-content-panel select,
.nightstands-wrapper.modal-page.plane-operations-modal .search-bar-field {
    width: 100%;
    flex: 0 0 100%;
    min-width: 100%;
}

.nightstands-wrapper.modal-page.plane-operations-modal .go-back-arrow {
    display: flex;
}

.nightstands-wrapper.modal-page.plane-operations-modal .search-bar-field {
    flex: 0 0 100%;
}


.nightstands-wrapper.modal-page.plane-operations-modal .nightstands-header h3 {
    order: 2;
    margin: 0;
}

.nightstands-wrapper.modal-page.plane-operations-modal .nightstands-container .nightstands-header {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 25px;
    flex-wrap: wrap;
}


.nightstands-wrapper.modal-page.plane-operations-modal .search-bar-field {
    width: 100%;
    min-width: 100%;
}

.nightstands-wrapper.modal-page.plane-operations-modal .nightstands-header svg.back-arrow {
    display: block !important;
}

.nightstands-wrapper.modal-page.plane-operations-modal .nightstands-container {
    display: flex;
    flex-direction: column;
}


.operations-plane-choice_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.nightstands-content-title .close-modal path {
    stroke: var(--red);
}

.nightstands-content-title .close-modal {
    padding: 0;
}

.nightstands-wrapper.modal-page.plane-operations-modal .search-bar-field {
    order: 3;
    margin-left: auto;
}

@media (min-width: 576px) {
    .nightstands-wrapper.modal-page.plane-operations-modal .nightstands-content-panel select,
    .nightstands-wrapper.modal-page.plane-operations-modal .search-bar-field {
        width: 45%;
        flex: 0 0 45%;
        min-width: 45%;
    }
}

@media (min-width: 576px) {
    .operations-plane-choice_list .materials_nav_item.plane_operations_nav_item {
        flex-basis: calc(100% / 2 - 20px);
    }
}
@media (min-width: 992px) {
    .nightstands-wrapper.modal-page.plane-operations-modal .nightstands-content-panel select,
    .nightstands-wrapper.modal-page.plane-operations-modal .search-bar-field {
        width: 35%;
        flex: 0 0 35%;
        min-width: 35%;
    }
    .operations-plane-choice_list .materials_nav_item.plane_operations_nav_item {
        flex: 0 0 calc(100% / 4);
    }
}
@media (min-width: 1200px) {
    .nightstands-wrapper.modal-page.plane-operations-modal .nightstands-content-panel select,
    .nightstands-wrapper.modal-page.plane-operations-modal .search-bar-field {
        width: 35%;
        flex: 0 0 35%;
        min-width: 35%;
    }
    .operations-plane-choice_list .materials_nav_item.plane_operations_nav_item {
        flex: 0 0 calc(100% / 6);
    }
}
@media (min-width: 1440px) {
    .operations-plane-choice_list .materials_nav_item.plane_operations_nav_item {

        flex: 0 0 calc(100% / 8);
    }
}
.nightstands-wrapper.modal-page.plane-operations-modal .plane-operation-action {
    gap: 10px;
    justify-content: center;
}

.nightstands-wrapper.modal-page.plane-operations-modal .cnc-title {
    display: block;
    cursor: pointer;
}

.plane-operation-action .form-input-checkbox-label.disabled:before {
    background: #e0dddd;
}

.plane-operation-action .form-input-checkbox-label.disabled:before {
    background: #e0dddd;
}

.delete-operation {
    position: relative;
}

.delete-operation-info {
    position: absolute;
    width: 280px;
    background: white;
    border: 1px solid var(--border);
    padding: 10px;
    left: 0;
    z-index: 1;
    display: none;
}

.delete-operation:hover .delete-operation-info {
    display: block;
}

.plane-operations-modal .current_operation_name {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
    width: 100px;
    text-overflow: ellipsis;
    margin: 0 auto 8px auto;
}