.actions {
    cursor: pointer;
    position: relative;
}

.logo-container .header-actions {
    height: inherit;
}

@keyframes warning-change-order {
    from {
        stroke: rgb(5, 0, 56);
    }
    to {
        background-color: orange;
    }
}

.stage-change__order svg path {
    stroke: orange;
    animation-name: warning-change-order;
    animation-duration: 0.9s;
    animation-iteration-count: infinite;

}

.header-wrapper {
    background-color: #f0f0f3;
}

.logo-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 5px;

}

@media (max-width: 768px) {
    .logo-container {
        flex-direction: column;
        background-color: white;
    }

    .logo-container .company,
    .logo-container .header-actions {
        width: 100%;
        justify-content: center;
    }
}

.company {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    width: max-content;
    /*height: 40px;*/
    background-color: #fff;
    padding: 5px 15px;
    position: relative;
    border-radius: 4px;
    gap: 8px;
}

.company-logo {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    max-width: 100px;
    overflow: hidden;
    height: 100%;
}

.company-logo img {
    width: 100%;
}

.company-details__name {
    position: relative;
    min-width: 25px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
}

.company-details {
    position: relative;
    border-right: 1px solid #eaeaea;
    border-left: 1px solid #eaeaea;
    display: flex;
    padding: 0 8px;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;
    height: inherit;
}

.header-icons {
    display: flex;
    justify-content: center;
    gap: 8px;
}

@media (min-width: 768px) {
    .header-icons {
        justify-content: space-between;
    }
}

.shoping-card {
    background-color: #fff;
    padding: 5px 16px 5px 10px;
    border-radius: 4px;
    position: relative;
}

.shoping-card .icon-question {
    position: absolute;
    right: 0;
    top: 1px;
    width: 16px;
}

.header-icons > div.user-avatar.vis {
    display: flex !important;
}

.header-icons > div.user-avatar {
    background-color: #fff;
    padding: 12px 8px;
    border-radius: 4px;
    display: none;
}

.header-tabs {
    display: flex;
    justify-content: center;
    margin: 20px 0 0 0;
}


.vertical-open {
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    flex-direction: column;
    z-index: 20;
    background-color: #fff;
    top: 49px;
    left: 100%;
    /*max-height: 280px;*/
    border: 1px solid #f0f0f3;;
}

.vertical-open-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
}

.header-icons > div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

.vertical-open-item span {
    width: 169px;
    padding-left: 10px;
}

.vertical-open-item {
    cursor: pointer;
}

.company-details .form-input {
    width: 115px;
    border-radius: 3px;
    outline: none;
    border: 1px solid #d9d8e1;
    padding: 6px;
    margin: 0;
    height: revert;
    font-size: 12px;
}


.company-details.edit label {
    display: none;
}

.main_service_header_save_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    height: inherit;
}

.main_service_header_save_button svg {
    margin-left: 9px;
    margin-right: 5px;
}

.company .actions {
    display: flex;
    align-items: center;
    height: inherit;
}

.header-social-wrap {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    align-items: center;
}
.header-social-wrap .header-social-item{
    background-color: #f0f0f3;
    border-radius: 4px;
}

.messengers_icons_header_container {
    width: 45px;
    background-color: #fff;
    padding: 8px;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.messengers_icons_header_container:hover .messengers_icons_header_qrs_code_container {
    display: block;
}

.messengers_icons_header_qrs_code_container {
    position: absolute;
    top: 100%;
    right: 100%;
    border-radius: 5px;
    padding: 9px;
    border: 1px solid #f0f0f3;
    background-color: #ffffff;
    text-align: center;
    display: none;
}

.messengers_icons_header_qrs_code {
    width: 150px;
    border-radius: 0;
    margin-bottom: 15px;
}

.header-icons > div.header-manager-data-container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 13px;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
}

.header-icons > div.header-manager-data-container small {
    font-weight: 500;
    opacity: 1;
    color: rgb(66, 98, 255);
    font-size: 11px;
}

.settings-helper-article,
.burger-menu-wrap {
    position: relative;
    padding: 5px 16px 0 0;
}

.settings-helper-article {
    padding: 3px 16px 0 0;
}

.settings-helper-article .icon-question,
.burger-menu-wrap .icon-question {
    position: absolute;
    top: -6px;
    right: 0;
    width: 16px;
}

.settings-helper-article .icon-question {
    top: -4px;
}

.container-menu {
    width: 315px;
    position: absolute;
    top: 34px;
    left: -300px;
    background-color: #fff;
    z-index: 30;
    border: 1px solid #f0f0f3;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
}

.container-menu .tab-container {
    flex-direction: column-reverse;
}

.container-menu .tab-container .tab {
    margin: 5px 0px;
}

.container-menu .messengers_icons_header_container,
.container-menu .shoping-card {
    margin: 0px;
    background-color: #f0f0f3;
}


.container-menu .icons-burger.header-icons {
    margin: 15px 0;
}

.logo-container.main-panel {
    justify-content: flex-end;
}

.burger-menu-wrap button.burger-btn {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    cursor: pointer;
    border: none;
    padding: 0px;

}

.burger-menu-wrap button.burger-btn .bars {
    background: rgb(5, 0, 56);
    display: block;
    width: 33px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;
    padding: 0;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

.burger-menu-wrap button.burger-btn .bars:first-child {
    transform-origin: 0 0;
}

.burger-menu-wrap button.burger-btn .bars:last-child(2) {
    transform-origin: 0 100%;
}

.burger-menu-wrap button.burger-btn.open .bars {
    opacity: 1;
    transform: rotate(
            45deg
    ) translate(-2px, -17px);
    background: #232323;
}

/*
 * But let's hide the middle one.
 */
.burger-menu-wrap button.burger-btn.open .bars:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
.burger-menu-wrap button.burger-btn.open .bars:nth-last-child(2) {
    transform: rotate(
            -45deg
    ) translate(-4px, 14px);
}


/*  --------- АДАПТИВ --------- */

@media (max-width: 1300px) {
    .header-icons > div.user-avatar {
        display: flex;
    }

    .header-icons > div.header-manager-data-container {
        display: none;
    }
}

@media (max-width: 1024px) {
    .header-tabs.header-tabs-tablet {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0px 15px;
    }

    .burger-menu-wrap {
        display: none;
    }

    .vertical-open {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.08);
        border-radius: 24px 24px 0px 0px;
        height: auto;
        z-index: 9999;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 25px 15px;
        justify-content: start;
    }

    .vertical-open:before {
        content: '';
        display: block;
        width: 35px;
        height: 4px;
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: #E9E9E9;
        border-radius: 5px;
    }
}

@media (max-width: 768px) {

    .header-icons .shoping-card {
        position: relative;
        margin-right: 0px;
        border-radius: 4px 0px 0px 4px;
    }

    .header-icons .user-avatar {
        border-radius: 0 4px 4px 0;
    }

    .company .company-logo {
        width: 100px;
    }

    .company .company-details {
        margin: 0 5px;
    }


}

@media (max-width: 576px) {
    .company .company-logo {
        display: none;
    }

    .header-tabs.header-tabs-tablet {
        justify-content: flex-start;
    }

    .company {
        padding: 0;
    }

    .company .company-details {
        margin: 0 5px 0 0;
        border-left: none;
    }

    .company .company-details__name {
        font-size: 12px;
        margin: 0 5px 0;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;

    }

}

@media (max-width: 576px) {

    .header-icons .user-avatar {
        padding: 10px 5px;
    }

    .header-icons .shoping-card {
        padding: 10px 18px 10px 5px;
    }

    .company .company-details__name {
        max-width: 50px;

    }
}


.select-custom {
    position: relative;
    width: max-content;
    margin: 0px 8px;

}

.select-custom li.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.select__head {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
}

.select__head svg {
    margin-right: 6px;
    padding: 0px;
    border: none;
}

.select__body {
    text-transform: uppercase;
    right: 0;
    text-align: center;
    position: absolute;
    top: 100%;
    background: white;
    border-radius: 4px;
    z-index: 4;
    list-style: none;
    padding: 5px;
    font-size: 14px;
    margin: 0;
    min-width: 40px;
    box-shadow: 0 0 5px rgb(217 217 219);
}

.select__body li {
    cursor: pointer;
    font-size: 16px;
    padding: 2px;
}

.select__body li .disabled {
    pointer-events: none;
    opacity: 0.4;
}

.burger-menu-wrap.basic-burger .burger-btn {
    background-color: white;
}

@media (max-width: 1200px) {
    .company-details__name {
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media (max-width: 375px) {
    .select__head {
        font-size: 0px;
    }

    .select__head svg {
        margin-right: 0px;
    }

    .company .company-details__name {
        max-width: 75px;
    }
}


.burger-menu-wrap.basic-burger {
    display: none;
}


@media (max-width: 992px ) {
    .basic-menu {
        margin-left: auto;
    }

    .burger-menu-wrap.basic-burger {
        display: block;
        margin-left: auto;
        margin-right: 8px;
    }

    .login-header-links-container {
        display: none;
        background: #fff;
        color: white;
        position: absolute;
        top: 100%;
        right: 0px;
        max-width: 280px;
        width: max-content;
        flex-direction: column;
        align-items: flex-start;
        border: 1px solid #f0f0f3;
        padding: 10px;
        margin: 0px;
        z-index: 3;
    }

    .login-header-links-container * {
        margin-bottom: 8px;
    }

    .basic-menu:hover .login-header-links-container {
        display: flex;
    }
}

.shoping-card.setting-firm.vertical-open-item {
    display: none;
}

@media (max-width: 1020px ) {
    .shoping-card.setting-firm.vertical-open-item {
        display: flex;
    }

    .shoping-card.setting-firm.vertical-open-item img {
        width: 25px;
    }
}

@media (max-width: 768px) {
    .login-header-links-container {
        display: none !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .basic-menu:hover .login-header-links-container {
        display: flex !important;
    }
}

.version-project-select {
    position: relative;
    display: flex;
    align-items: center;
}

.version-project-select ul {
    position: absolute;
    top: 100%;
    right: 0;
    width: max-content;
    background: white;
    box-shadow: 0 0 5px #ccc;
    padding: 0 0;
    border-radius: 8px;
    margin: 0;
    z-index: 2;
    display: none;
    overflow: hidden;
    max-height: 320px;
    overflow-y: auto;
}

.version-project-select span {
    position: relative;
    left: 0;
    top: 0;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 0px 5px 0px 8px;
}

.version-project-select span svg.back-arrow {
    margin: 0 0 0 5px;
    width: 5px;
    transform: rotate(-90deg);
}

.version-project-select span:hover svg.back-arrow {
    transform: rotate(90deg);
}

.version-project-select:hover ul {
    opacity: 1;
    z-index: 3;
    display: block;
}

.version-project-select ul li:not(.selected):hover {
    background: rgba(221, 221, 221, 0.55);
}

.version-project-select ul li {
    font-size: 13px;
    padding: 10px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.version-project-select ul li svg {
    max-height: 20px;
    margin-left: 8px;
}

.version-project-select ul li.selected {
    pointer-events: none;
    background: #007bff29;
}

.version-project-select__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.version-project-select__actions > span {
    cursor: pointer;
}

.version-project-select .btn {
    margin-top: 5px;
    padding: 5px !important;
    font-size: 10px !important;
    text-transform: uppercase;
    text-align: center;
    display: block;
}

.header-wrapper.details-header .card3d-wrap {
    box-sizing: border-box;
    cursor: pointer;
}

@media (min-width: 1440px) {
    .header-wrapper.details-header .logo-container {
        justify-content: center;
    }

    .header-wrapper.details-header .header-actions {
        margin: 0 25px;
    }

    .cabinet-wrapper .col.main {
        padding: 0;
    }
}

.logo-container .actions-container {
    height: 100%;
}

.logo-container .singl-action {
    box-sizing: border-box;
    padding: 10px 10px;
}

.header-tabs.header-tabs-tablet .tab-container .tab {
    padding: 5px 5px 5px 5px;
    margin: 0 0 2px 0;
    white-space: nowrap;
}

.header-icons > div.header-manager-data-container > a {
    display: block;
    white-space: nowrap;
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
}