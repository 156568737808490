.goods-service-part-wrap .row .form-input{
    height: revert;
    padding: 8px;
}
.goods-service-part-wrap {
    width: 100%;
}
.goods-service-part-wrap .row {
    display: grid;
    grid-template-columns: 50% 20% 20% 1fr;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;
}
.goods-service-part-wrap .row .col{
    margin: 0;
}
.goods-service-part-wrap .btn-add {
    width: max-content;
    padding: 5px 15px !important;
    margin: 0;
}
.goods-service.modal-body {
    overflow-y: auto;
    max-height: 70vh;
    padding: 10px 20px 20px 20px;
}
.goods-service-part-wrap .row .label{
    padding-top: 0;
}