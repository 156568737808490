.memory-modal-body .list li{
    list-style: circle;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-size: 16px;
    color: #050038;
}
.memory-modal-body p{
    font-size: 16px;
    color: #050038;
    text-align: center;
    margin-bottom: 35px;
    font-weight: 500;
}
.memory-modal-body  .btn.invers{
    border: 2px solid #3586FF;
    color: #3586FF;
    background: transparent;
    font-weight: 400;
}