.pagination_container_main {
    padding: 15px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
}

.pagination_container_main .page-link {
    padding: 6px 10px;
    border: 1px solid #c0c5ca;
    cursor: pointer;
    text-align: center;
    width: auto;
    height: 31px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-right: 3px;
    margin-bottom: 3px;
    border-radius: 5px;
}
.pagination_container_main .page-link.active {
    color: #fff;
    background-color: #4262ff;
    text-align: center;
}
.page_link_separator {
    height: 1px;
    width: 34px;
    background-color: #4262ff;
    margin: 0 5px;
}

@media (max-width: 1024px) {
    .pagination_container_main{
        justify-content: center;
    }
}