.project_materials_main_container {
    width: 459px;
    position: fixed;
    top: 0;
    left: 0;
    /*height: 100vh;*/
    height: calc(100vh - calc(100vh - 100%));
    background-color: #ffffff;
    padding: 25px;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #D2D2D6;
}

.project_materials_header_title {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.project_materials_header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}
.project_materials_header .remove-all{
    zoom: 1.2;
    fill: red;
}
.project_materials_header .close-modal{
    padding: 0px;
}
.project_materials_item_container {
    border: 1px solid #D2D2D6;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 19px;
    width: 100%;
    margin-bottom: 19px;
    min-height: 150px;
}

.project_materials_item_container {
    display: flex;
    /*flex-flow: row nowrap;*/
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-right: 60px;
}
.connected_band.burger-menu-wrap{
    display: block !important;

}
.project_materials_item_container .burger-menu-wrap button.burger-btn {
    background-color: white;
}
.project_materials_item_container .burger-menu-wrap button.burger-btn .bars{
    height: 6px;
    margin-bottom: 3px;
}
.project_materials_item_container .project_materials_item__connected_band .form-input-checkbox:checked + span:after{
    left: 4px;
}
.project_materials_item_container .project_materials_item__connected_band{
    align-items: center;
    display: flex;
    margin-top: 15px;
    width: 100%;
    flex: 0 0 100%;
}
.project_materials_item_image {
    width: 54px;
    height: 54px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border: 1px solid #D2D2D6;
    border-radius: 9px;
    overflow: hidden;
    margin-right: 25px;
}

.project_materials_item_image img {
    width: 100%;
}

.project_materials_item_delete {
    width: 45px;
    margin-left: 13px;
    opacity: 0;
    position: absolute;
    right: 19px;
}
.project_materials_item_delete .checkbox-label__input {
    padding: 0;
    text-align: center;
    width: 100%;
    height: 30px;
    display: flex;
}
.project_materials_item_delete .checkbox-label__input input:checked + label:after,
.project_materials_item_delete .checkbox-label__input label:before {
    width: 20px;
    height: 20px;
    margin: 0;
    left: calc(50% - 13px);
    font-size: 16px;
}
.project_materials_item_delete .form-input-checkbox-label {
    width: 100%;
    height: 30px;
}

.project_materials_item_container:hover .project_materials_item_delete {
    opacity: 1;
}

.project_materials_item_info_name {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #050038;
    margin-bottom: 5px;
    word-wrap: break-word;
}

.project_materials_item_info_type {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #605D81;
}

.project_materials_item_info {
    width: 214px;
}

.events-none {
    cursor: auto;
}

.events-none svg {
    pointer-events: none;
    opacity: 0.5;
}

.project_materials_header .back-arrow {
    display: none;
}

@media (max-width: 1024px) {
    .project_materials_header {
        justify-content: center;
        position: relative;
    }

    .project_materials_header .close-modal {
        display: none;
    }

    .project_materials_header .back-arrow {
        display: block;
        position: absolute;
        left: 0;
    }

    .project_materials_item_delete {
        opacity: 1;
    }
}

@media (max-width: 768px) {

}

@media (max-width: 576px) {
    .project_materials_main_container {
        width: 100%;
        border-right: 0px;
    }

    .project_materials_item_container {
        padding: 15px 40px 15px 25px;
    }

    .project_materials_main_container .project_materials_item_image {
        width: 45px;
        height: 45px;
        padding: 5px;
        margin-right: 10px;
        margin-bottom: 8px;
    }

    .project_materials_item_info_name {
        font-size: 14px;
        line-height: 16px;
    }

    .project_materials_item_delete {
        width: 40px;
        margin-left: 0;
        opacity: 1;
        right: 20px;
    }
}

@media (max-width: 1024px) {
    .project_materials_main_container {
        z-index: 8;
    }
}

.material-change-position-arrow {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}

.circle-arrow {
    width: 20px;
    height: 20px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

svg.arrow-down {
    transform: rotate(-90deg);
    /*width: 18px;*/
}

svg.arrow-top {
    transform: rotate(90deg);
    /*width: 18px;*/
}

.project_materials_item_container .circle-arrow svg {
    height: 12px !important;
}

.band-colorPicker {
    position: relative;
    top: 5px;
}

.colorPicker-btn {
    width: 50px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid #ddd;
    padding: 6px;
    display: block;
    cursor: pointer;
}
.project_materials_item-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}
.container-picker {
    position: absolute;
    z-index: 1;
    top: -220px;
}

.project_materials_item_container.choice .project_materials_item_delete svg{
    opacity: 0;
    pointer-events: none;
}
.project_materials_content {
    margin-top: 25px;
}
.project_materials_item_container.choice .project_materials_item_delete{
    opacity: 1;
}
.project_materials_item_container.choice {
    border-color: var(--green);
}

