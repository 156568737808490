.header .basic-menu {
    margin: 0 auto;
}

.wrapper {
    width: 100vw;
    height: 100vh;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px;
}

.header .main-logo {
    max-width: 115px;
    min-width: 100px;
}

.register {
    color: #050038;
    padding: 13px 24px;
    border: 1px solid #c0bfcd;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    display: block;
}

.main-login-page-container.wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.main-login-page-container .container.login-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.main-login-page-container .header {
    width: 100%;
    margin-bottom: 15px;
}


.login-header-links-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
}

.login-header-links-container a {
    display: block;
    height: auto;
    text-decoration: none;
    font-size: 13px;
    margin: 5px 15px;
    color: #4262ff;
}

.login-item .logo {
    padding: 0px 0px 20px 0px;
}

@media (max-width: 992px) {
    .header .basic-menu {
        margin: 0 0 0 auto;
    }
}

@media (max-width: 1024px) {
    .header {
        padding: 0px 15px;
    }

    .basic-menu + .select-custom {
        margin-right: 0px;
    }

    .logo {
        padding: 10px 0px;
    }
}

/*new style*/
.choice-type-login {
    margin-bottom: 25px;
    justify-content: center;
    gap: 10px;
}

.choice-type-login input[type="radio"] {
    margin-right: 3px;
}
.choice-type-login input[type="radio"] + label{
    cursor: pointer;
}
.form-sm {
    max-width: 100%;
    width: 420px;
    margin: 0 auto;
    box-sizing: border-box;
}

.card-form {
    padding: 15px 15px;
    border: 1px solid var(--border);
    border-radius: 3px;
}

section.section-login-platform {
    margin: 0 0 120px 0;
}

.section-login-platform .tab-root {
    margin-bottom: 45px;
}

.section-login-platform .tab-root .tab-section {
    justify-content: center;
    flex-wrap: wrap;

}

.section-login-platform .tab-root {
    background-color: var(--grey);
    gap: 8px
}

.section-login-platform .tab-root .tab {
    cursor: pointer;
    padding: 12px 8px;
    border-bottom: 3px solid #cdccd7;
    font-size: 14px;
    font-weight: 400;
}

.section-login-platform .tab-root .tab.active {
    border-color: blue;
    color: var(--blue);
}

.form-title {
    text-align: center;
    font-weight: 500;

}

.form-sub-title {
    font-size: 20px;
    text-align: center;
    font-weight: 400;
}

.switch-form-tab {
    border-top: 1px solid var(--grey);
    margin-top: 20px;
    padding: 20px 0 0;
}

.forgot-password {
    font-size: 14px;
    color: var(--blue);
}

@media (min-width: 992px) {
    .section-login-platform .tab-root .tab {
        font-size: 18px;
    }
}

.section-connect-firms-platform {
    margin: 55px 0 120px;
}

.section-connect-firms-platform ul {
    margin-top: 35px;
    gap: 12px;
}

.choice-firm-register ul {
    max-height: 350px;
    overflow-y: auto;
}

.step-register-bot .content {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.custom-btn-prev {
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

span.custom-btn-prev .icon-arrow {
    transform: rotate(90deg);
}

.register-page-header {
    margin-bottom: 20px;
}


/*MESSAGE CHANNEL*/
.message-channel-entry {
    flex-wrap: wrap;
    gap: 10px;
}

.un-connected-channel-entry .item {
    position: relative;
    z-index: 1;
}

.un-connected-channel-entry .item img {
    display: none;
    width: 550px;
    max-width: min-content;
    position: absolute;
    transform: translateY(-100%);
}

.un-connected-channel-entry .item:hover img {
    display: block;
}

.resend-password,
.resend-action {
    font-size: 14px;
    text-decoration: underline;
    color: var(--orange);
    display: block;
    margin: 12px 0;
    cursor: pointer;
}

.form-group + .resend-password {
    margin-top: -15px;
}

.resend-password.disabled {
    color: var(--gray-violet);
}

.connected-channel .item {
    display: flex;
    cursor: pointer;
    padding: 8px;
    border: 1px solid var(--gray-violet);
    justify-content: center;
    border-radius: 6px;
}

.connected-channel .item.active,
.connected-channel .item.default {
    border: 1px solid var(--blue);
}

.connected-channel .item:hover {
    box-shadow: 0 0 4px #c2c2c2;
}

.bot-info {
    position: relative;

}

.bot-info .info + img {
    box-shadow: 0 0 5px #ccc;
    display: none;
    max-width: 100%;
    width: 450px;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
}

.bot-info .info {
    cursor: pointer;
    margin-left: 8px;
}

.bot-info .info:hover + img {
    display: block;
    z-index: 5;

}

.seconds {
    color: #2a8000;
    font-size: 15px;
    font-weight: 600;
}
.privacy-checkbox input[type="checkbox"]{
    margin-right: 8px;
}

.auth_firm_set_container {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
}
.auth_firm_set_item {
    border: 1px solid #c0bfcd;
    width: 241px;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
}
.main-login-page-container .btn{
    padding: 14px 8px !important;
}
.social-login{
    margin-top: 1.5rem;
    border-top: 1px solid var(--border);
    padding-top: 1.5rem;
}
.gap-10{
    gap: 10px;
}