.settings-section {
    width: 100%;
}

.settings-section .head {
    margin-bottom: 25px;
}

.settings-section .main-parts-table thead th {
    text-align: center;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.settings-section .head .field select {
    display: block;
}

.settings-section .filed label {
    font-size: 18px; /* display: block; */
    margin-right: 12px;
    color: #605d81;
    font-weight: bold;
}

.material-select {
    width: max-content;
    max-width: 100%;
    padding: 8px 12px;
}

.settings-section .main-parts-table td {
    padding: 10px;
    color: #605d81;
}

.settings-section .main-parts-table {
    padding: 15px 0px 0px 0px;
    border: 1px solid #f0f0f3;
}

.settings-section .table-wrapper tr:last-child td {
    border-bottom: 0px;
}

.settings-section .input-val {
    width: max-content;
    max-width: 200px;
}

.settings-section input.disabled {
    border: none !important;
    background: none;
    opacity: 1;
}

.settings-section .form-input-checkbox-label:before {
    border: 1px solid #cdccd7 !important;
    background-color: white;
}

.settings-section .form-input-checkbox-label {
    width: 30px;
    height: 19px;
    margin: 0 auto;
}

.settings-section .form-input-checkbox:checked + span:after {
    left: 6px;
}

.inst__bore-form {
    padding: 40px;
}

.inst__bore-form .field-section {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 15px));
    grid-column-gap: 15px;
    margin-bottom: 15px;
    align-items: flex-start;
}

.inst__bore-form .field-section .input-val {
    width: 100%;
    max-width: 100%;
}

.inst__bore-form .field-section .btn {
    margin-top: auto;
    border: none;
    outline: none;
    width: max-content;
    padding: 12px 5px;
    min-width: 120px;
    margin-left: auto;

}

.inst__bore-form label {
    color: #605d81;
}

.inst__bore-form .field {
    /*margin-bottom:25px;*/
}

.settings-section .field-title {
    font-size: 16px;
    margin-bottom: 8px;
}

.settings-section .field-radio {

    display: block;
    cursor: pointer;
}

.settings-section .field-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.settings-section .field-radio:not(:last-child) {
    margin-bottom: 10px;
}

.settings-section .field-radio .checkmark {
    position: relative;
    padding-left: 25px;
}

.settings-section .field-radio .checkmark::before {
    content: '';
    display: block;
    position: absolute;
    top: 2.5px;
    left: 0;
    height: 8px;
    width: 8px;
    background-color: #eee;
    border-radius: 50%;
    border: 5px solid #eee;
    box-sizing: content-box;
}

.field.select-sides {
    display: flex;
    flex-direction: column;
}

.settings-section .field-radio input:checked ~ .checkmark::before {
    background-color: white;
    border: 5px solid #3586FF;
}

.settings-section .field-on label.form-input-checkbox-label {
    width: 100%;
    height: auto;
}

.field-on label.form-input-checkbox-label {
    justify-content: flex-start;
}

.field-on .form-input-checkbox:checked + span:after {
    left: 5px;
}

.tabs-settings {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    width: 100%;
    justify-content: center;
}

.tabs-settings .item {
    margin: 5px 8px;
    color: #605d81;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    background-color: transparent;
    cursor: pointer;
}

.tabs-settings .item.active {
    color: white;
    background-color: #050038;
}

.settings-section .settings-actions .info svg {
    position: relative;
    top: 5px;
    width: 25px;
}

.settings-section .settings-actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.settings-section .settings-actions span {
    cursor: pointer;
    margin: 0px 4px;
}

.settings-section .settings-actions .edit svg {
    width: 22px;
    height: 22px;
}

.settings-section .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.settings-section .section-header .btn {
    width: max-content;
    min-width: 120px;
    border: none;
    margin: 0px;
}

.tools-wrap:not(:last-child) {
    margin-bottom: 45px;
}

@media (max-width: 576px) {
    .choice-material__current + .inst__bore-form {
        padding: 0px;
        width: 100%;
    }

    .inst__bore-form {
        padding: 20px 15px;
        width: calc(100% - 15px);
    }

    .inst__bore-form .field-section {
        grid-template-columns: 100%;
        grid-column-gap: 0px;
    }
}


.sides-radio label,
.sides-radio input[type="radio"] {
    cursor: auto !important;
}


.container-info-plane .item {
    display: grid;
    grid-template-columns: 220px 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    align-items: center;
}

.container-info-plane .item .label {
    padding-left: 15px;
}

.container-info-plane {
    /*margin-bottom: 35px;*/
    border: 1px solid #f0f0f3;
    border-bottom: none;
}

.container-info-plane .item:last-child {
    border-bottom: 1px solid #f0f0f3;
}

.container-info-plane:not(:first-child)
.container-info-plane:not(:last-child) {
    margin-bottom: 35px;
}

.container-info-plane .item:nth-child(even) {
    background: rgba(66, 98, 255, 0.1);
}

.settings-section .table {
    overflow-y: auto;
}

.table-scroll {
    overflow-y: auto;
}

.select-custom.select-custom__add-stocks ul.select__body {
    width: max-content;
    right: 0;
    left: revert;
    font-size: 12px;
}

.select-custom.select-custom__add-stocks ul.select__body li:hover {
    color: rgb(39, 76, 255);
}

.select-custom.select-custom__add-stocks ul.select__body li {
    font-size: 13px;
    text-align: left;
    font-weight: 500;
    margin: 5px 0px;
}

.settings-section .go-back {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.settings-section .go-back svg {
    margin-right: 10px;
}

.choice-type-stock_btns {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 25px;
}

.choice-type-stock_btns .btn {
    margin: 5px 5px;
    width: max-content;
}

.choice-material__current label {
    padding: 0px;
}

.choice-material__current .name {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.choice-material__current .name strong {
    font-size: 16px;
    display: block;
    width: max-content;
    max-width: calc(100% - 40px);
    text-overflow: ellipsis;
    overflow: hidden;
}

.btn.btn-maxCCenter {
    width: max-content;
    margin: 0 auto;
    display: block;
    padding: 15px 25px;
}

@media (min-width: 768px) {
    .btn.btn-maxCCenter {
        padding: 15px 25px !important;
    }
}

@media (max-width: 768px) {

    .container-info-plane .item {
        display: grid;
        grid-template-columns: 150px 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 15px;
        align-items: center;
    }

    .container-info-plane .item div {
        padding: 10px 10px;
    }
}

@media (max-width: 576px) {

    .container-info-plane .item {
        display: grid;
        grid-template-columns: 35% 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 15px;
        align-items: center;
    }

    .container-info-plane .item div {
        padding: 10px 10px;
    }
}

.edit-icon-def {
    margin: 0px 5px;
    display: inline-block;
    cursor: pointer;
}


.d-block {
    display: block !important;
}

.field.field-change-comment {
    text-align: right;
}

.btn.btn-maxCCenter.min-padd {
    padding: 10px !important;
    margin: 5px auto !important;
    font-size: 12px !important;
    text-transform: uppercase;
}

.service-price-option {
    display: flex;
    align-items: center;
    justify-content: center;
}

.settings-section .service-price-option .input-val,
.service-price-option input,
.service-price-option select {
    margin: 0px 5px;
    width: max-content;
    max-width: 120px;
}

.form-material-product-price {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.form-material-product-price .field {
    margin: 5px 5px;
}

.form-material-product-price .field * {
    margin: 0px;
}

.furniture-owl__item {
    position: relative;
}

.furniture-owl__item {
    padding-top: 20px;
}

span.furniture-delete__photo {
    position: absolute;
    top:0;
    z-index: 2;
    left: 0px;
    width: 20px;
    border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.card-wrapper.card-materials.card-furniture-wrap .card-img {
    height: 160px;
}

.card-furniture-wrap .carousel.carousel-slider .control-arrow {
    top: 20px;
}

.carousel .slider-wrapper.axis-horizontal .slider, .carousel-root .carousel.carousel-slider .slider-wrapper .slide > div > img, .carousel-root .carousel.carousel-slider .slider-wrapper .slide > div, .carousel-root .carousel.carousel-slider .slider-wrapper .slide, .carousel-root .carousel.carousel-slider .slider-wrapper, .carousel-root .carousel.carousel-slider {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
}

@media (min-width: 0px) {
    .furniture-card-container {
        justify-content: center;
        flex-wrap: wrap;
    }
}
.furniture-card-container .card-materials.card-wrapper{
    flex: 0 0 calc(100%);
    max-width: 315px;
    margin: 0px 0px 15px 0px;
}
@media (min-width: 768px) {
    .furniture-card-container .card-wrapper.card-materials.card-furniture-wrap {
        flex: 0 0 calc(100%);
        max-width: 315px;
        margin: 0px 0px 15px 0px;
    }

    .furniture-card-container {
        justify-content: center;
    }
}

@media (min-width: 768px) {
    .furniture-card-container {
        justify-content: flex-start;
    }

    .furniture-card-container .card-wrapper.card-materials.card-furniture-wrap {
        flex: 0 0 calc(100% / 2 - 15px);
        max-width: 315px;
        margin: 0px 15px 15px 0px;
    }
}

@media (min-width: 1440px) {
    .furniture-card-container {
        justify-content: flex-start;
    }

    .furniture-card-container .card-wrapper.card-materials.card-furniture-wrap {
        flex: 0 0 calc(100% / 4 - 15px);
        max-width: 315px;
        margin: 0px 15px 15px 0px;
    }
}
.goods-stock__plans svg >  path{
    stroke: #4262ff;
}