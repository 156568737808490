.tab-container {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 20px;
    gap: 30px;
}

.tab {
    font-size: 15px;
    font-weight: 400;
    padding: 8px 24px;
    border-radius: 20px;
    margin: 5px;
    cursor: pointer;
    color: #050038;
}

.is-active {
    background-color: #f0f0f3;
}

.tab-import-container,
.header-tabs.header-tabs-tablet {
    border-bottom: 1px solid #DBDBE2;
}

.header-tabs.header-tabs-tablet .tab-container .tab:last-child {
    margin-right: 0;
}



.tab-import-container .tab.active:before,
.header-tabs.header-tabs-tablet .tab-container .tab.is-active:before {
    content: '';
    display: block;
    height: 3px;
    left: 0;
    right: 0;
    background: rgb(66, 98, 255);
    position: absolute;
    bottom: -2px;
}

.tab-import-container .tab,
.header-tabs.header-tabs-tablet .tab-container .tab {
    padding: 5px 5px 15px 5px;
    color: rgba(96, 93, 129, 1);
    font-size: 16px;
    position: relative;
    font-weight: 400;
}

.header-tabs.header-tabs-tablet .tab-container {
    background: transparent;
}

.tab-import-container {
    padding: 0;
    justify-content: center;
    border-radius: 0;
}

.tab-import-container .tab {
    margin: 0;
}

