.packaging-print-form .form-group{
    margin-bottom: 25px;
}
.packaging-print-form .form-group .item.level_3,
.packaging-print-form .form-group .item.level_2{
    padding-left: 25px;
}
.packaging-print-form .form-group .item{
    margin-bottom: 12px;
}
